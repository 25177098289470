/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/


import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    displayColumn: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "1em",
    },
    subTitle: {
        fontSize: 14,
        fontWeight: "500",
    },
    subColor: {
        color: props => props.contrast === "" ? "#666" : "white",
    },
    root: {
        backgroundColor: props => props.contrast === "" ? "" : "black",
        color: props => props.contrast === "" ? "#666" : "white",
        border: props => props.contrast === "" ? "1px solid #666" : "1px solid white",
    }
});

export default function MobileList(props) {
    const classes = useStyles(props);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar
                        aria-label="avatar"
                        style={
                            props.contrast === "" ? (
                                props.backColor
                                    ? { backgroundColor: props.backColor }
                                    : { backgroundColor: "gray" }
                            )
                            :
                            (
                                {backgroundColor: "black"}
                            )
                        }
                    >
                        {props.avatar}
                    </Avatar>
                }
                title={props.title}
                subheader={<Typography className={classes.subColor}>{props.subtitle}</Typography>}
                action={
                    <>
                        {
                            props.href ?
                                <Link to={props.href}>
                                    <IconButton
                                        onClick={props.reset}
                                    >
                                        <VisibilityIcon style={{fill: props.contrast === "" ? "#00bcd4" : "yellow"}} />
                                    </IconButton>
                                </Link> : null
                        }

                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon style={{fill: props.contrast === "" ? "#666" : "yellow"}}/>
                        </IconButton>
                    </>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {props.data.map((info, index) => (
                        <div className={classes.displayColumn} key={index}>
                            <Typography className={classes.subTitle}>
                                {info.title}
                            </Typography>
                            <Typography>
                                {info.subtitle}
                            </Typography>
                        </div>
                    ))}
                </CardContent>
            </Collapse>
        </Card>
    );
}
