/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close';
import Profile from '../../../../../img/default_profile0.png'
import Cropper from './Cropper.js'

function ChooseImage(props) {
    return (
        <div style={{ marginTop: "0" }}>
            <DivAlterarFoto>
                <DivFlowHolder>
                    <AvatarCircleDiv>
                        <img src={props.avatar}
                            alt="user avatar" style={{ height: "inherit", width: "inherit", objectFit: "cover" }}
                        />
                        <input accept="image/*" id="avatar-file"
                            type="file"
                            onChange={(e) => props.handleFile(e.target.files)}
                            style={{ display: "none" }}
                        />
                        <label for="avatar-file" style={{ width: "inherit" }}>
                            <ChangeAvatarDiv >
                                <span>Alterar</span>
                            </ChangeAvatarDiv>
                        </label>
                    </AvatarCircleDiv>
                </DivFlowHolder>
            </DivAlterarFoto>
            {
                props.tempImg &&
                <img alt="" src={props.tempImg} />
            }
            <ButtonsDiv>
                <ButtonCancelar onClick={props.handleClose}>
                    <span>Cancelar</span>
                </ButtonCancelar>

            </ButtonsDiv>
        </div>
    )
}


export default function ComponentAlterarAvatar(props) {
    const [uploadingImage, setUploading] = useState(false)

    const handleFile = (selectorFiles) => {
        const objectURL = URL.createObjectURL(selectorFiles[0])
        setTempImg(objectURL)
        setUploading(true)
    }

    const [tempImgURL, setTempImg] = useState(null)

    const [newAvatar, setNewAvatar] = useState('')
    const updateAvatar = (avatar) => {
        setNewAvatar(avatar)
    }

    const [crop] = useState({
        unit: "%",
        width: 30,
        aspect: 1
    });

    const completeSelection = () => {
        props.handleComplete(newAvatar)
    }

    return (
        <ModalDiv>
            <HeaderDiv>
                <span style={{ width: "32px" }} />
                <StyledH2>{uploadingImage ? 'Alterar foto do Item' : 'Editar foto do Item'}</StyledH2>
                <StyledCloseModalButton onClick={props.handleClose}>
                    <CloseIcon style={{ color: "#666" }} />
                </StyledCloseModalButton>
            </HeaderDiv>
            <DialogDiv>
                {
                    uploadingImage ?
                        (
                            [
                                <>
                                    <EditarDiv>
                                        <TextoEditarDiv>
                                            Clique nos ícones e arraste para selecionar a parte que você quer da foto
                                        </TextoEditarDiv>
                                        <div style={{ maxWidth: "500px", maxHeight: "300px" }}>
                                            <Cropper src={tempImgURL} crop={crop} circularCrop={true} update={updateAvatar} />
                                        </div>
                                    </EditarDiv>
                                    <FooterButtonsDiv>
                                        <ButtonCancelar onClick={props.handleClose}>CANCELAR</ButtonCancelar>
                                        <ButtonConfirmar onClick={() => { completeSelection() }}>SELECIONAR FOTO</ButtonConfirmar>
                                    </FooterButtonsDiv>
                                </>
                            ]
                        )
                        :
                        (
                            [
                                <>
                                    <ChooseImage
                                        avatar={Profile}
                                        handleFile={handleFile}
                                        handleClose={props.handleClose}
                                        tempImg={tempImgURL}
                                    />
                                </>
                            ]
                        )
                }
            </DialogDiv>
        </ModalDiv>
    )

}

const FooterButtonsDiv = styled.div`
    padding : 0 30px 20px;
    display : flex;
    flex-direction : row;
    align-content : center;
    justify-content : space-between;
    Button {
        margin-top : 20px;
    }
`

const TextoEditarDiv = styled.div`
    margin-bottom : 15px;
    align-self : center;
    text-align : center;
    font-size : 15px;
`

const EditarDiv = styled.div`
    position : relative;
    background-color : #f4f4f4;
    padding : 20px 30px 40px;
`

const ChangeAvatarDiv = styled.div`
    color : rgba(255,255,255,.7);
    background-color:rgba(0,0,0,.5);
    position: absolute;
    bottom: 0;
    width: inherit;
    text-align: center;
    font-size: 18px;
    padding-bottom: 5px;
    font-weight: 400;
    height: 30%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ModalDiv = styled.div`
    background-color : #fff;
    border-radius : 4px;
    min-width : 560px;
    color : #666;
    display: flex;
    flex-direction : column;
    @media screen and (max-width: 959px) {
        height : 100%;
        width : 100%;
    }
`
const ButtonConfirmar = styled(Button)`
    background-color : #00bcd4 !important;
    color : #fff !important;
    border-radius : 3px !important;
`

const ButtonCancelar = styled(Button)`
    &:hover {
        background-color : rgba(158,158,158,0.2) !important;
    }
    background-color : #fff !important;
    color : #666 !important;
    text-decoration : none !important;
    outline : none !important;
    text-align : center !important;
`

const ButtonsDiv = styled.div`
display : flex;
justify-content:flex-end;
`

const AvatarCircleDiv = styled.div`
margin-bottom : 0;
border-radius : 50%;
height : 150px;
width : 150px;
position : relative;
overflow: hidden;
`

const DivFlowHolder = styled.div`
align-self : auto;
`

const DivAlterarFoto = styled.div`
display : flex;
margin-bottom : 30px;
flex-direction : row;
align-items : center;
justify-content :center;
`

const DialogDiv = styled.div`
padding : 20px 30px;
overflow : visible !important;
`

const HeaderDiv = styled.div`
display : flex;
flex-direction : row;
align-items : center;
align-content : center;
justify-content : space-between;
max-width : 100%;
`
const StyledH2 = styled.h2`
font-size : 26px;
font-weight : normal;
margin-top : 20px;
margin-bottom : 10px;
font-family: inherit;
line-height: 1.1;
color: inherit;
`
const StyledCloseModalButton = styled(Button)`
display : inline-block;
position : relative;
float : right !important;
background : transparent !important;
min-width: 0 !important;
width : 40px;
border-radius : 50%;
padding : 8px;
height : 40px;
margin : 0 6px;
`
