/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useState, useEffect } from "react";
import moment from "moment";
// Maerial ui components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import { useStyles } from "../../Styles/DataCard";
import Grid from "@material-ui/core/Grid";
// Icons
import EditRoundedIcon from "@material-ui/icons/EditRounded";
//imports from local files
import { GetAData, DeleteFilter, EditFilter } from "../../../Filters";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../Components/LoadingSpinner";
import SnackBar from "../../../../Components/SnackbarComponent";
import {
  getRequest,
  deleteRequest,
  putRequest
} from "../../../../Components/HelperFunctions/getAxiosConfig";
import Paper from "@material-ui/core/Paper";
import styled from 'styled-components'
import { GiAchievement } from 'react-icons/gi'

const AchievementCard = ({ match }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const WINDOW_WIDTH = window.innerWidth
  const [error, setError] = useState(false) //Necessary to consult the API, catch errors
  const [isLoaded, setIsLoaded] = useState(false) //Necessary to consult the API, wait until complete
  const [item, setItem] = useState()
  const [reloadPage, setReloadPage] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    icon: "",
    open: false,
    color: "",
  });

  const DisplayDate = (date) => {
    const convertedData = moment.utc(date);
    return moment(convertedData)
      .format("LLL")
      .toString();
  };

  const HandleSnack = (message, state, icon, color) => {
    setSnackInfo({
      message: message,
      icon: icon,
      open: state,
      color: color,
    });
  };

  const inactiveItem = (item) => {
    const body = {
      "achievement": {
        "state": "inactive"
      }
    }
    putRequest(
      EditFilter("achievements", item.id),
      body,
      (data) => {
        if (data.errors)
          HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
        else {
          HandleSnack(
            "O achievement foi inativado com sucesso",
            true,
            "success",
            "#228B22"
          );
        }
        setReloadPage(!reloadPage)
      },
      (error) => {
        HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
      }
    )
  }

  const activeItem = (item) => {
    const body = {
      "achievement": {
        "state": "active"
      }
    }
    putRequest(
      EditFilter("achievements", item.id),
      body,
      (data) => {
        if (data.errors)
          HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
        else {
          HandleSnack(
            "O achievement foi ativado com sucesso",
            true,
            "success",
            "#228B22"
          );
        }
        setReloadPage(!reloadPage)
      },
      (error) => {
        HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
      }
    )
  }

  const deleteHandler = () => {
    deleteRequest(
      DeleteFilter("achievements", item.id),
      (data) => {
        if (data.errors)
          HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
        else {
          HandleSnack(
            "O item foi deletada com sucesso",
            true,
            "success",
            "#228B22"
          );
        }
        navigate(-1)
      },
      (error) => {
        HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
      }
    )
  }


  const FastActions = (item) => {
    switch (item.state) {
      case "deleted":
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                style={{ backgroundColor: "#FF8C00", color: "#FFFAFA", width: "150px" }}
                onClick={() => inactiveItem(item)}
              >
                Inativar
                            </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                style={{ backgroundColor: "#228B22", color: "#FFFAFA", width: "150px" }}
                variant="contained"
                onClick={() => activeItem(item)}
              >
                Ativar
                            </Button>
            </Grid>
          </Grid>
        );
      case "inactive":
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                style={{ backgroundColor: "#FA8072", color: "#FFFAFA", width: "150px" }}
                onClick={deleteHandler}
              >
                Remover
                            </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                style={{ backgroundColor: "#228B22", color: "#FFFAFA", width: "150px" }}
                variant="contained"
                onClick={() => activeItem(item)}
              >
                Ativar
                            </Button>
            </Grid>
          </Grid>
        );
      case "active":
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Button
                style={{ backgroundColor: "#FA8072", color: "#FFFAFA", width: "150px" }}
                variant="contained"
                onClick={deleteHandler}
              >
                Remover
                            </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ backgroundColor: "#FF8C00", color: "#FFFAFA", width: "150px" }}
                variant="contained"
                onClick={() => inactiveItem(item)}
              >
                Inativar
                            </Button>
            </Grid>
          </Grid>
        );
      default:
        return "NOTHING";
    }
  };

  const StateItem = (status) => {
    switch (status) {
      case "deleted":
        return (
          <Paper
            style={{
              textAlign: "center",
              padding: "0.5em",
              backgroundColor: "#FA8072",
              fontWeight: "500",
              color: "#FFFAFA",
            }}
          >
            REMOVIDO
          </Paper>
        );
      case "inactive":
        return (
          <Paper
            style={{
              textAlign: "center",
              padding: "0.5em",
              backgroundColor: "#FF8C00",
              fontWeight: "500",
              color: "#FFFAFA",
            }}
          >
            INATIVO
          </Paper>
        );
      case "active":
        return (
          <Paper
            style={{
              textAlign: "center",
              padding: "0.5em",
              backgroundColor: "#228B22",
              fontWeight: "500",
              color: "#FFFAFA",
            }}
          >
            ATIVO
          </Paper>
        );
      default:
        return "NOTHING";
    }
  };

  useEffect(() => {
    setIsLoaded(false)
    getRequest(
      GetAData("achievements", match.params.id),
      (data, header) => {
        setItem(data);
        setIsLoaded(true);
        setError(false);
      },
      (error) => {
        setIsLoaded(true);
        setError(true);
      }
    );
  }, [reloadPage]);

  if (error) {
    return <div>Houve um erro</div>;
  } else if (!isLoaded) {
    return <LoadingSpinner text="Carregando..." />;
  } else {
    return (
      <>
        <SnackBar
          severity={snackInfo.icon}
          text={snackInfo.message}
          snackbarOpen={snackInfo.open}
          color={snackInfo.color}
          handleClose={() =>
            setSnackInfo({
              message: "",
              icon: "",
              open: false,
              color: "",
            })
          }
        />
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                  container
                >
                  <Grid item>
                    <Typography
                      className={classes.title}
                      color="inherit"
                      gutterBottom
                    >
                      Informações do item
                                        </Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/admin/achievements`}
                    >
                      <Button
                        startIcon={<ListRoundedIcon />}
                        color="primary"
                        variant="outlined"
                      >
                        Listar
                                            </Button>
                    </Link>
                    <Link
                      to={`/admin/EditAchievement/${match.params.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        startIcon={<EditRoundedIcon />}
                        color="primary"
                        variant="outlined"
                      >
                        Editar
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <div style={{ height: "1em" }} />
                <Grid container direction="row" spacing={3}>
                  <Grid item sm={5} xs={12} style={WINDOW_WIDTH >= 600 ? {
                    borderRight: "solid #d4d4d4 1px"
                  } : {
                      borderBottom: "solid #d4d4d4 1px"
                    }}>
                    <ImgDiv>
                      <GiAchievement size={200} color="#e81f4f" />
                    </ImgDiv>
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography
                          color="initial"
                          className={classes.subTitle}
                        >
                          Nome
                          </Typography>
                        <Typography color="textSecondary">
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography
                              color="initial"
                              className={classes.subTitle}
                            >
                              Experiência ganha
                            </Typography>
                            <Typography style={{ color: "#228B22" }}>
                              {"+" + (item.reward_experience) + " experiência"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              color="initial"
                              className={classes.subTitle}
                            >
                              Points ganhos
                            </Typography>
                            <Typography style={{ color: "#228B22" }}>
                              {"+" + (item.reward_points) + " points"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="initial"
                          className={classes.subTitle}
                        >
                          Criado em
                                                </Typography>
                        <Typography color="textSecondary">
                          {DisplayDate(item.created_at)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="initial"
                          className={classes.subTitle}
                        >
                          Atualizado em
                                                </Typography>
                        <Typography color="textSecondary">
                          {DisplayDate(item.updated_at)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="column" spacing={1}>
                  {
                    item.achievement ?
                      <>
                        <Grid item>
                          <Typography color="initial" className={classes.subTitle}>
                            Como desbloquear
                                                    </Typography>
                          <Typography color="textSecondary">
                            {item.achievement.description}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="initial" className={classes.subTitle}>
                            Recompensa de xp
                                                    </Typography>
                          <Typography style={{ color: "#228B22" }}>
                            {"+" + item.achievement.reward_experience + " xp"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="initial" className={classes.subTitle}>
                            Recompensa de points
                                                    </Typography>
                          <Typography style={{ color: "#228B22" }}>
                            {"+" + item.achievement.reward_points + " points"}
                          </Typography>
                        </Grid>
                      </>
                      : null
                  }
                  <Grid item>
                    <Typography color="initial" className={classes.subTitle}>
                      Descrição
                    </Typography>
                    <Typography color="textSecondary">
                      {item.description}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="initial" className={classes.subTitle}>
                      Requisitos
                    </Typography>
                    <Typography color="textSecondary">
                      <ul>
                        {
                          item.requirements.map((req) => {
                            return (
                              <li key={req.created_at}>
                                {req.description}
                              </li>
                            )
                          })
                        }
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <div className={classes.displayColumn}>
                  <Typography
                    className={classes.title}
                    color="inherit"
                    gutterBottom
                  >
                    Estado
                                    </Typography>
                  <Typography color="textSecondary">
                    {StateItem(item.state)}
                  </Typography>
                </div>
                <div className={classes.displayColumn}>
                  <Typography
                    className={classes.title}
                    color="inherit"
                    gutterBottom
                  >
                    Ações
                                    </Typography>
                  <Typography color="textSecondary">
                    {FastActions(item)}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default AchievementCard;

const ImgDiv = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center;
  height: 100%; 
  width: 100%;
`
