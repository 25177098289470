/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

/*Mudar a API para homologa para rodar gamefication */
  //var apiDomain = 'https://api.portalmechomologa.c3sl.ufpr.br',
  var apiDomain = 'https://api.portalmec.c3sl.ufpr.br',
  //var apiDomain = 'https://api.portalmec.c3sl.ufpr.br',
  //var apiDomain = 'http://localhost:3000',
  apiVersion = 'v1',
  apiUrl = apiDomain + '/' + apiVersion;

var simcaqAPIDomain = 'https://www.simcaq.c3sl.ufpr.br/api',
  apiSimcaqVersion = 'v1',
  simcaqAPIurl = simcaqAPIDomain + '/' + apiSimcaqVersion


export { apiUrl };
export { apiDomain };
export { simcaqAPIurl }
