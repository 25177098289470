/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useContext } from "react";
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import styled from 'styled-components'
import { Store } from 'Store'

const PageHeader = (props) => {
    const { state } = useContext(Store);
    return (
        <Paper style={{marginBottom: "2em", marginTop: "2em", marginLeft: "1em", marginRight: "1em"}}>
            <StyledGrid contrast={state.contrast} container spacing={3} direction="row" alignItems="center">
                <Grid item xs={6}>
                    <Typography variant="h4">{props.title}</Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Grid container justify="flex-end" spacing={3}>
                        {props.actions.map((act, index) => {
                            return (
                                <Grid item key={act.name}>
                                    <StyledButton
                                        contrast={state.contrast}
                                        variant="contained"
                                        disabled={act.isLoading}
                                        onClick={act.func}
                                        startIcon={act.icon}
                                    >
                                        <div style={state.contrast === "" ? {color: "white"} : {color: "yellow"}}>
                                            {act.isLoading ? <CircularProgress size={24} /> : act.name}
                                        </div>
                                    </StyledButton>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </StyledGrid>
            {props.children}
        </Paper>
    )
}

const StyledGrid = styled(Grid)`
    background-color: ${props => props.contrast === "" ? "white" : "black"};
    color: ${props => props.contrast === "" ? "#666" : "white"};
    border: 1px solid ${props => props.contrast === "" ? "#666" : "white"};
    border-radius: 10px;
`

const StyledButton = styled(Button)`
    background-color: ${props => props.contrast === "" ? "#ff7f00" : "black"} !important;
    color: white !important;
    text-decoration: ${props => props.contrast === "" ? "none" : "underline yellow"} !important;
    border: 1px solid ${props => props.contrast === "" ? "" : "white"} !important;
`

export const StyledFilter = styled.div`
    background-color: ${props => props.contrast === "" ? "white" : "black"};
    color: ${props => props.contrast === "" ? "#666" : "white"};
    border: 1px solid ${props => props.contrast === "" ? "#666" : "white"};
    border-radius: 0 0 10px 10px;
    padding: 10px;
    margin-top: 10px;
`

export default PageHeader;