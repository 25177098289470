import React, { useContext } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Store } from '../../../Store';


function ButtonComponent({ text, color, icon, variant, onClick, disabled }) {
    const { state } = useContext(Store);

    if (state.contrast === "") {
        return (
            <Button
                startIcon={icon}
                color={color}
                variant={variant}
                onClick={onClick}
                disabled={disabled}
                style={{margin: "5px"}}
            >
                {text}
            </Button>
        )
    }
    else {
        return (
            <ContrastButton
                startIcon={icon}
                onClick={onClick}
                variant={variant}
                disabled={disabled}
            >
                {text}
            </ContrastButton>
        );
    }
}

const ContrastButton = styled(Button)`
    background-color: black !important;
    border: 1px solid white !important;
    color: yellow !important;
    text-decoration: underline !important;
    :hover {
        background-color: rgba(255,255,0,0.24) !important;
    }
    :disabled {
        color: white !important;
        text-decoration: none !important;
    }
    svg {
        fill: white !important;
    }
    margin: 5px !important;
`

export default ButtonComponent
