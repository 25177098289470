import React from 'react';
// material ui componets
import Table from '@material-ui/core/Table';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import { IconButton } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        width: "99.99%",
        overflow: "hidden"
    },
    // CSS DO LUIS_GAMEFICATION
    root: {
        minWidth: 275,
        boxShadow: '2px 2px 1px #A9A9A9'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 28,
        fontWeight: "500"
    },
    pos: {
        marginBottom: 12,
    },
});


const TableData = (props) => {
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {
                            props.top.map((top, index) => (
                                top === "ID" ?
                                    <StyledTableCell key={index}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {top}
                                            {
                                                props.onIconPressed &&
                                                <IconButton onClick={props.onIconPressed} color='primary'>
                                                    <FilterListRoundedIcon style={{ color: 'white' }} />
                                                </IconButton>
                                            }
                                        </div>
                                    </StyledTableCell>
                                    :
                                    <StyledTableCell align="right" key={index}>{top}</StyledTableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                {props.children}
            </Table>
        </TableContainer>
    );
}

export default TableData; 