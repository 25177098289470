/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terxs of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useEffect } from 'react';
import './ContrastBar.css';
import ContrastImageOn from '../../img/OnContrastIcon.png';
import ContrastImageOff from '../../img/OffContrastIcon.png';
import { Store } from '../../Store';
import Grid from "@material-ui/core/Grid"
import { Button } from '@material-ui/core';
import styled from 'styled-components';

/**
 * Bar allowing for the toggle of the high contrast mode on the page.
 */
function ContrastBar() {
    /* eslint-disable */

    // Hook to set contrast context
    const { state, dispatch } = React.useContext(Store);

    const handleScrollTo = (location) => {
        if (location === "Pesquisa_scroll")
            dispatch({
                type: 'HANDLE_SEARCH_BAR',
                opened: !state.searchOpen
            })
        document.getElementById(location).scrollIntoView({ behavior: 'smooth' });
	}

    useEffect(() => {
        const testContrast = localStorage.getItem('@portalmec/contrast');
        if (testContrast) {
            setContrastAction(testContrast);
        } else {
            setContrastAction('');
        }
    }, []);

    const setContrastAction = (newContrast) => {
        localStorage.setItem('@portalmec/contrast', newContrast)
        return dispatch({
            type: 'SET_CONTRAST',
            payload: newContrast
        })
    }

    const setFontSizeAction = (newFontSize) => {
        return dispatch({
            type: 'SET_FONT_SIZE',
            payload: newFontSize
        })
    }

    const toggleContrast = () => {
        var status = (state.contrast === '' ? 'Contrast' : '')

        setContrastAction(status)
    }
    const incrementFontSize = () => {
        document.getElementsByTagName("body")[0].style.fontSize = (parseInt(state.fontSize, 10) + 1) + "px";
        setFontSizeAction(parseInt(state.fontSize, 10) + 1);
    }
    const decrementFontSize = () => {
        document.getElementsByTagName("body")[0].style.fontSize = (parseInt(state.fontSize, 10) - 1) + "px";
        setFontSizeAction(parseInt(state.fontSize, 10) - 1);
    }
    const defaultFontSize = () => {
        setFontSizeAction(15);
        document.getElementsByTagName("body")[0].style.fontSize = "15px";
    }

    return (
        <React.Fragment>
            <div style={{
                backgroundColor: '#00bcd4',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '20px',
                textAlign: 'center'
            }}>
                <h4 style={{ color: '#fff' }}>
                    <a style={{ color: '#f9f900' }}>Fique atento, pois essa versão será desligada dentro de alguns meses!</a><br />
                    A plataforma está passando por uma transformação! Visite{' '}
                    <a href="https://mecred.mec.gov.br" target="_blank" rel="noopener noreferrer" style={{ color: '#0000ff', textDecoration: 'underline' }}>
                        mecred.mec.gov.br
                    </a>.<br />
                    Gradualmente, todas as funcionalidades serão migradas para a nova plataforma. Acompanhe o desenvolvimento desta inovação do MEC em colaboração com {' '}
                    <a href="https://www.c3sl.ufpr.br" target="_blank" rel="noopener noreferrer" style={{ color: '#0000ff', textDecoration: 'underline' }}>
                        C3SL
                    </a>.
                </h4>

            </div>
            <StyledGrid contrast={state.contrast} xs={12}>
                {
                    window.innerWidth > 750 ? 
                    (
                        <>
                            <Grid xs={8}>
                                <StyledButton style={{justifyContent: "flex-start"}}>
                                    <a href="/" accessKey="1" title="Ir para a página principal alt + 1">
                                        <span className={`${state.contrast}text`}>Plataforma MEC de Recursos Educacionais Digitais</span>
                                    </a>
                                </StyledButton>
                            </Grid>
                            <Grid xs={4} style={{display: "flex"}}>
                                <Grid xs={3}>
                                    <StyledButton onClick={() => handleScrollTo("Conteudo_scroll")}>
                                        <a className={`${state.contrast}text`} accessKey="2" title="Ir para o conteúdo alt + 2">
                                            Conteúdo
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid xs={3}>
                                    <StyledButton onClick={() => handleScrollTo("Pesquisa_scroll")}>
                                        <a className={`${state.contrast}text`} accessKey="3" title="Ir para o menu e a barra de pesquisa alt + 3">
                                            Menu
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid xs={3}>
                                    <StyledButton onClick={() => handleScrollTo("Rodape_scroll")}>
                                        <a className={`${state.contrast}text`} accessKey="4" title="Ir para o rodapé alt + 4">
                                            Rodapé
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid xs={3}>
                                    <StyledButton onClick={toggleContrast}>
                                        <a className={`${state.contrast}text`} title={state.contrast === "" ? "Ativar alto contraste" : "Desativar alto contraste"}>
                                            <img className='Constrasticon' src={state.contrast === "" ? ContrastImageOff : ContrastImageOn} style={{ paddingRight: "15px" }} alt="Ícone de contraste" />
                                        </a>
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </>
                    )
                    :
                    (
                        <>
                            <Grid xs={12} style={{display: "flex"}}>
                                <Grid xs={3}>
                                    <StyledButton>
                                        <a href="/" accessKey="1" title="Ir para a página principal alt + 1">
                                            <span className={`${state.contrast}text`}>MEC RED</span>
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid xs={3}>
                                    <StyledButton onClick={() => handleScrollTo("Conteudo_scroll")}>
                                        <a className={`${state.contrast}text`} accessKey="2" title="Ir para o conteúdo alt + 2">
                                            Conteúdo
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid xs={2}>
                                    <StyledButton onClick={() => handleScrollTo("Pesquisa_scroll")}>
                                        <a className={`${state.contrast}text`} accessKey="3" title="Ir para o menu e a barra de pesquisa alt + 3">
                                            Menu
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid xs={3}>
                                    <StyledButton onClick={() => handleScrollTo("Rodape_scroll")}>
                                        <a className={`${state.contrast}text`} accessKey="4" title="Ir para o rodapé alt + 4">
                                            Rodapé
                                        </a>
                                    </StyledButton>
                                </Grid>
                                <Grid xs={1}>
                                    <StyledButton onClick={toggleContrast}>
                                        <a className={`${state.contrast}text`} title={state.contrast === "" ? "Ativar alto contraste" : "Desativar alto contraste"}>
                                            <img className='Constrasticon' src={state.contrast === "" ? ContrastImageOff : ContrastImageOn} style={{ paddingRight: "15px" }} alt="Ícone de contraste" />
                                        </a>
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </>   
                    )
                }
            </StyledGrid>
        </React.Fragment>
    );

}

const StyledGrid = styled(Grid) `
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    background: ${props => props.contrast === "" ? "white" : "black"};
    border-bottom: ${props => props.contrast === "" ? "1px solid #666" : "1px solid white"};
`

const StyledButton = styled(Button)`
    width: 100%;
`

/*
<div>
    <a className={`${state.contrast}text`} onClick={incrementFontSize} title="Aumentar tamanho da fonte">
        A+
    </a>
        <a className={`${state.contrast}text`} onClick={decrementFontSize} title="Diminuir tamanho da fonte">
        A-
    </a>
        <a className={`${state.contrast}text`} onClick={defaultFontSize} title="Restaurar tamanho da fonte">
        A
    </a>
</div>
*/

export default ContrastBar;