/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useContext } from 'react'
import Alert from './Alert.js';
import Snackbar from '@material-ui/core/Snackbar';
import { Store } from '../Store.js'

export default function SnackbarComponent(props) {
    const { state } = useContext(Store)

    return (
        <Snackbar open={props.snackbarOpen} autoHideDuration={2000} onClose={props.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity={props.severity} style={state.contrast === "" ? props.color ? { backgroundColor: props.color } : {} : { backgroundColor: "black", color: "white", border: "1px solid white" }}>
                {props.text}
            </Alert>
        </Snackbar>
    )
}
