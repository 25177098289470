/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/ 

import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import styled from 'styled-components'

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    root: {
        minWidth: 900,
        boxShadow: "2px 2px 1px #A9A9A9",
    },
    title: {
        fontSize: 28,
        fontWeight: "500",
    },
    subTitle: {
        fontSize: 14,
        fontWeight: "500",
    },
    pos: {
        marginBottom: 12,
    },
    displayRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    displayColumn: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "1em",
    },
    marginTop : {
        marginTop : '1em',
    }
}); 

const StyledCard = styled(Card)`
    background-color: ${props => props.contrast === "" ? "white" : "black"} !important;
    color: ${props => props.contrast === "" ? "#666" : "white"} !important;
    border: 1px solid ${props => props.contrast === "" ? "#666" : "white"} !important;
`

export { useStyles, StyledCard };