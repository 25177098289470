/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/
import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import { Store } from '../../../Store'
import StyledButton from './Button';

const AlertDialog = ( props ) => { 

    const { state } = useContext(Store)

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.HandleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledDiv contrast={state.contrast}>
                    <DialogTitle id="alert-dialog-title">
                        Deseja deletar o dado de id: {props.deleteItem.id}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Se você deletar essa dado, todas as informações desse dado serão deletas para sempre
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <StyledButton onClick={props.HandleClose} color="primary" text="Não deletar"/>
                        <StyledButton onClick={props.OnDelete} color="secondary" autoFocus text="Deletar"/>
                    </DialogActions>
                </StyledDiv>
            </Dialog>
        </div>
    );
} 

const StyledDiv = styled.div`
    background-color: ${props => props.contrast === "" ? "white" : "black"};
    color: ${props => props.contrast === "" ? "#666" : "white"};
    border: 1px solid ${props => props.contrast === "" ? "#666" : "white"};

    #alert-dialog-description {
        color: ${props => props.contrast === "" ? "#666" : "white"};
    }
`

export default AlertDialog;