/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useState, useContext, useEffect } from 'react'
//imports material ui componets
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardAction from '@material-ui/core/CardActions'
import { Typography, TextField, Button, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import MenuItem from "@material-ui/core/MenuItem"
//imports local files
import SnackBar from '../../../../../Components/SnackbarComponent'
import { Store } from '../../../../../Store'
import Unauthorized from '../../Unauthorized'
import ModalEditPhoto from "./ModalEditPhoto"
import { getRequest, putRequest } from "../../../../../Components/HelperFunctions/getAxiosConfig"
import LoadingSpinner from '../../../../../Components/LoadingSpinner'
import { GetAData } from '../../../../Filters'
//router
import { Link } from 'react-router-dom'

const EditItem = ({ match }) => {
  const { state } = useContext(Store)

  const [name, setName] = useState('Editar Item')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [discount, setDiscount] = useState('')
  const [itemState, setItemState] = useState('')
  const [itemType, setItemType] = useState('')
  const [achievementID, setAchievementID] = useState('')
  const [img, setImg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  const [errorInName, setErrorInName] = useState({
    error: false,
    message: '',
  })
  const [errorInDescription, setErrorInDescription] = useState({
    error: false,
    message: '',
  })
  const [errorInPrice, setErrorInPrice] = useState({
    error: false,
    message: ''
  })
  const [errorInObjType, setErrorInObjType] = useState({
    error: false,
    message: ''
  })

  const [snackInfo, setSnackInfo] = useState({
    message: '',
    icon: '',
    open: false,
    color: '',
  })

  const stateOptions = [
    { name: "active", value: "Ativo" },
    { name: "inactive", value: "Inativo" },
    { name: "removed", value: "Removido" },
  ]

  const typesOptions = [
    { name: "avatar_frame", value: "Moldura de avatar" },
    { name: "badge", value: "Insígnia" },
    { name: "card_frame", value: "Moldura de card do usuário" },
    { name: "cover_frame", value: "Moldura de capa" },
  ]


  const NameHandler = (e) => {
    if (errorInName.error) {
      setErrorInName({
        error: false,
        message: ''
      })
    }
    setName(e.target.value)
  }
  const DescriptionHandler = (e) => {
    if (errorInDescription.error)
      setErrorInDescription({
        error: false,
        message: ''
      })
    setDescription(e.target.value)
  }
  const priceHandler = (e) => {
    if (errorInPrice.error)
      setErrorInPrice({
        error: false,
        message: ''
      })
    setPrice(e.target.value)
  }
  const itemTypeHandler = (e) => {
    if (errorInObjType.error)
      setErrorInObjType({
        error: false,
        message: ''
      })
    setItemType(e.target.value)
  }
  const discountHandler = (e) => {
    setDiscount(e.target.value)
  }
  const itemStateHandler = (e) => {
    setItemState(e.target.value)
  }
  const achievementIdHandler = (e) => {
    setAchievementID(e.target.value)
  }

  // Handle snack infos
  const HandleSnack = (message, state, icon, color) => {
    setSnackInfo({
      message: message,
      icon: icon,
      open: state,
      color: color
    })
  }

  const CheckUserPermission = () => {
    let canUserEdit = false

    if (state.userIsLoggedIn) {
      const roles = [...state.currentUser.roles]
      for (let i = 0; i < roles.length; i++)
        if (roles[i].name === 'admin' || roles[i].name === 'editor')
          canUserEdit = true
    }
    else {
      canUserEdit = false
    }

    return canUserEdit
  }


  //Handle submit 
  async function onSubmit(image) {
    setIsLoading(true)
    const api = `/items/${match.params.id}`
    let body = {}
    if (!image)
      body = {
        "item": {
          "name": name,
          "description": description,
          "price": parseInt(price),
          "discount": discount ? parseInt(discount) : 0,
          "state": itemState,
          "item_type": itemType,
          "achievement_id": parseInt(achievementID),
        }
      }
    else
      body = {
        "item": {
          "name": name,
          "description": description,
          "price": parseInt(price),
          "discount": discount ? parseInt(discount) : 0,
          "state": itemState,
          "item_type": itemType,
          "achievement_id": parseInt(achievementID),
          "image": image
        }
      }
    putRequest(
      api,
      body,
      (data) => {
        if (data.id)
          HandleSnack('O item foi alterado com sucesso!', true, 'success', '#228B22')
        else {
          if (!data)
            HandleSnack(`Erro com servidor interno!`, true, 'warning', '#FA8072')
          if (data.errors) {
            HandleSnack(`${data.errors[0]}`, true, 'warning', '#FA8072')
          }
          if (data.name) {
            let nameError = ""
            data.name.map((msg) => (
              nameError = nameError + msg + " e "
            ))
            setErrorInName({
              error: true,
              message: nameError
            })
          }
          if (data.price) {
            let priceError = ""
            data.price.map((msg) => (
              priceError = priceError + msg + " e "
            ))
            setErrorInPrice({
              error: true,
              message: priceError
            })
          }
          if (data.description) {
            let descriptionError = ""
            data.description.map((msg) => (
              descriptionError = descriptionError + msg + " e "
            ))
            setErrorInDescription({
              error: true,
              message: descriptionError
            })
          }
          if (data.item_type) {
            let objTypeError = ""
            data.objType.map((msg) => (
              objTypeError = objTypeError + msg + " e "
            ))
            setErrorInObjType({
              error: true,
              message: objTypeError
            })
          }
        }
        setIsLoading(false)
      },
      (error) => {
        HandleSnack('Ocorreu algum erro', true, 'warning', '#FA8072')
        setIsLoading(false)
      }
    )
  }

  // Fields
  const fields = [
    {
      select: false,
      label: 'Nome',
      value: name,
      required: true,
      error: errorInName.error,
      errorMessage: errorInName.message,
      onChange: (event) => NameHandler(event)
    },
    {
      select: false,
      label: 'Descrição',
      value: description,
      required: false,
      error: errorInDescription.error,
      errorMessage: errorInDescription.message,
      onChange: (event) => DescriptionHandler(event)
    },
    {
      select: false,
      label: 'Preço',
      value: price,
      required: false,
      error: errorInPrice.error,
      errorMessage: errorInPrice.message,
      onChange: (event) => priceHandler(event)
    },
    {
      select: false,
      label: 'Desconto',
      value: discount,
      required: false,
      onChange: (event) => discountHandler(event)
    },
    {
      select: true,
      label: 'Estado',
      value: itemState,
      options: [...stateOptions],
      required: false,
      onChange: (event) => itemStateHandler(event)
    },
    {
      select: true,
      label: 'Tipo',
      value: itemType,
      required: false,
      options: [...typesOptions],
      error: errorInObjType.error,
      errorMessage: errorInObjType.message,
      onChange: (event) => itemTypeHandler(event)
    },
    {
      select: false,
      label: 'ID do achievement',
      value: achievementID,
      required: false,
      onChange: (event) => achievementIdHandler(event)
    },
  ]

  useEffect(() => {
    getRequest(
      GetAData("items", match.params.id),
      (data, header) => {
        setName(data.name)
        setDescription(data.description)
        setPrice(data.price)
        setDiscount(data.discount)
        setItemState(data.state)
        setItemType(data.item_type)
        setAchievementID(data.achievement ? data.achievement.id : "")
        setImg(data.image)
        setError(false)
        setIsLoaded(true)
      },
      (error) => {
        setIsLoaded(true)
        setError(true)
      }
    )
  }, [])

  if (error) {
    return <div> Error... </div>
  } else if (!isLoaded) {
    return <LoadingSpinner text="Carregando..." />
  } else if (CheckUserPermission()) {
    return (
      <Card>
        <ModalEditPhoto
          open={open}
          handleClose={() => { setOpen(false) }}
          handleComplete={(img) => {
            setOpen(false)
            onSubmit(img)
          }}
          userAvatar={img}
          id={state.currentUser.id}
        />
        <SnackBar
          severity={snackInfo.icon}
          text={snackInfo.message}
          snackbarOpen={snackInfo.open}
          color={snackInfo.color}
          handleClose={() => setSnackInfo({
            message: '',
            icon: '',
            open: false,
            color: ''
          })}
        />
        <CardContent>
          <Grid container direction='row' justify='space-between' alignContent="center" alignItems="center" xs={12}>
            <Grid item>
              <Typography variant='h4'>
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Link style={{ textDecoration: 'none' }} to={'/admin/gamefication'}>
                <Button
                  // onClick={props.BackToList}
                  startIcon={<ListRoundedIcon />}
                  variant='outlined'
                  color='primary'
                >
                  Listar
                </Button>
              </Link>
            </Grid>
          </Grid>

          <div style={{ height: '1em' }}></div>
          <form style={{ display: 'flex', flexDirection: 'column' }}>
            {fields.map((field, index) => (
              field.select ?
                <TextField
                  select
                  key={index}
                  required={field.required}
                  error={field.error}
                  helperText={field.error ? field.errorMessage : ''}
                  style={{ width: '250px', marginBottom: '1em' }}
                  label={field.label}
                  value={field.value}
                  onChange={field.onChange}
                  type="search"
                  multiline={true}
                >
                  {field.options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      value={option.name}
                      name={option.value}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
                :
                <TextField
                  key={index}
                  required={field.required}
                  error={field.error}
                  helperText={field.error ? field.errorMessage : ''}
                  style={{ width: '250px', marginBottom: '1em' }}
                  label={field.label}
                  value={field.value}
                  onChange={field.onChange}
                  type="search"
                  multiline={true}
                />
            ))}
          </form>
        </CardContent>
        <CardAction>
          <Button
            onClick={() => {
              setOpen(true)
            }}
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading ? null : <AddRoundedIcon />}
          >
            {
              isLoading ? <CircularProgress size={24} /> : 'Editar'
            }
          </Button>
        </CardAction>
      </Card>
    )
  } else return <Unauthorized />
}

export default EditItem