import { useState } from 'react'
import {apiUrl} from '../../env.js'

export function getAxiosConfigFromJSON () {
    let config = {
        headers : JSON.parse(localStorage.getItem('@portalmec/auth_headers'))
    }
    return config
}

export function updateHeaders (newHeaders) {

    let newToken = getNewAccessToken(newHeaders['access-token'])

    localStorage.setItem('@portalmec/accessToken', newToken)

    let auth_headers = JSON.parse(localStorage.getItem('@portalmec/auth_headers'))
    /*const auth_headers = {
        client: newHeaders.get('client'),
        "access-token": newHeaders.get('access-token'),
        uid: newHeaders.get('uid'),
        expiry: newHeaders.get('expiry'),
        "token-type": "Bearer"
    }

    localStorage.setItem('@portalmec/auth_headers', JSON.stringify(auth_headers))*/
    if (auth_headers) {
        auth_headers['access-token'] = newHeaders['access-token']
    }
    else {
        auth_headers = {
            client: newHeaders.client,
            "access-token": newHeaders['access-token'],
            uid: newHeaders.uid,
            expiry: newHeaders.expiry,
            "token-type": "Bearer"
        }
    }
    localStorage.setItem('@portalmec/auth_headers', JSON.stringify(auth_headers))
}

function fetchHeaders () {
    let auth_headers = JSON.parse(localStorage.getItem('@portalmec/auth_headers'))

    var myHeaders = undefined
    if (auth_headers) {
        myHeaders = new Headers(auth_headers)
        myHeaders.set('Cache-Control', 'no-cache, no-store, must-revalidate');
        myHeaders.set('Pragma', 'no-cache');
        myHeaders.set('Expires', '0');
        return myHeaders
    }
    else {
        myHeaders = new Headers()
        myHeaders.set('Cache-Control', 'no-cache, no-store, must-revalidate');
        myHeaders.set('Pragma', 'no-cache');
        myHeaders.set('Expires', '0');
        return myHeaders
    }
}

function checkPreviousTokens (new_token) {

    let prev_tokens = JSON.parse(localStorage.getItem('@portalmec/tokens'))

    if (prev_tokens) {
        if (!prev_tokens.hasOwnProperty(new_token)) {
            prev_tokens[new_token] = 1
            localStorage.setItem('@portalmec/tokens', JSON.stringify(prev_tokens))
            return true
        }
        else {
            return false
        }
    }
    else {
        let tokens = {}
        tokens[new_token] = 1
        localStorage.setItem('@portalmec/tokens', JSON.stringify(tokens))
        return true
    }

}

function getNewAccessToken (newAccessToken) {
    if (!newAccessToken || newAccessToken.trim().length === 0) {
        return localStorage.getItem('@portalmec/accessToken')
    }
    else {
        return newAccessToken
    }
}


function updateAccessToken (newAccessToken) {
    let newToken = getNewAccessToken(newAccessToken)

    if (checkPreviousTokens(newToken)) {

        localStorage.setItem('@portalmec/accessToken', newToken)

        let auth_headers = JSON.parse(localStorage.getItem('@portalmec/auth_headers'))

        if (auth_headers) {
            auth_headers['access-token'] = newToken
        }

        localStorage.setItem('@portalmec/auth_headers', JSON.stringify(auth_headers))
    }
}
export async function getRequest (url, onSuccess, onError) {
    let response = await fetch((`${apiUrl}${url}`), {
        headers : fetchHeaders()
    })
    if (response.ok) {
        if (response.headers.has('access-token')) {
            updateAccessToken(response.headers.get('access-token'))
        }
        let json = await response.json()
        onSuccess(json, response.headers)
    }
    else {
        onError(response.status)
    }
        // .then(response => {
        //     if (response.headers.has('access-token')) {
        //         updateAccessToken(response.headers.get('access-token'))
        //     }
        //     let json = await response.json()
        //
        //     onSuccess(json)
        //
        //     return response.json().catch(err => {
        //         return {};
        //     })
        // })
        // .then(data => {
        //     onSuccess(data)
        // })
        // .catch(error => {
        //     onError(error)
        // })
}

export const deleteRequest = (url, onSuccess, onError) => {
    fetch((`${apiUrl}${url}`), {
        method : 'DELETE',
        headers : fetchHeaders()
    })
        .then(response => {
            if (response.headers.has('access-token')) {
                updateAccessToken(response.headers.get('access-token'))
            }
            return response.json().catch(err => {
                return {};
            })
        })
        .then(data => {
            onSuccess(data)
        })
        .catch(error => {
            onError(error)
        })
}




export const putRequest = (url, payload, onSuccess, onError) => {
    let newHeaders = fetchHeaders()
    if (!(payload instanceof FormData)) {
        newHeaders.append('Content-Type', 'application/json')
    }

    fetch((`${apiUrl}${url}`), {
        method : 'PUT',
        headers : newHeaders,
        body: payload instanceof FormData ? payload : JSON.stringify(payload)
    })
        .then(response => {
            if (response.headers.has('access-token')) {
                updateAccessToken(response.headers.get('access-token'))
            }
            return response.json().catch(err => {
                return {};
            })
        })
        .then(data => {
            onSuccess(data)
        })
        .catch(error => {
            onError(error)
        })
}

export const postRequestCurating = (url, payload, onSuccess, onError) => {
    let newHeaders = fetchHeaders()
    if (payload instanceof FormData) {
        newHeaders.append('Content-Type', 'multipart/form-data')
    }
    else {
        newHeaders.append('Content-Type', 'application/json')
    }
    // Faz a chamada pro back e se der certo retorna o json, caso tenha erro retorna o status do erro
    fetch((`${apiUrl}${url}`), {
        method : 'POST',
        headers : newHeaders,
        body: payload instanceof FormData ? payload : JSON.stringify(payload)
    })
        .then(response => {
            if (response.headers.has('access-token')) {
                updateAccessToken(response.headers.get('access-token'))
            }
            if (response.ok) {
                onSuccess(response.json)
            }
            else {
                onError(response.status)
            }
        })
}

export const postRequest = (url, payload, onSuccess, onError) => {
    let newHeaders = fetchHeaders()
    if (payload instanceof FormData) {
        newHeaders.append('Content-Type', 'multipart/form-data')
    }
    else {
        newHeaders.append('Content-Type', 'application/json')
    }
    // Faz a chamada pro back e se der certo retorna o json, caso tenha erro retorna o status do erro
    fetch((`${apiUrl}${url}`), {
        method : 'POST',
        headers : newHeaders,
        body: payload instanceof FormData ? payload : JSON.stringify(payload)
    })
        .then(response => {
            if (response.headers.has('access-token')) {
                updateAccessToken(response.headers.get('access-token'))
            }
            return response.json().catch(err => {
                return(response.status)
            })
        })
        //Catch mal feito
        .then(data => {
            if(data>=400)
                onError(data)
            else
                onSuccess(data)
        })
}

export async function fetchAllRequest (urls, onSuccess, onError) {
    let responses = await Promise.all( urls.map( url =>  fetch(`${apiUrl}${url}`,  {
        headers : fetchHeaders()
    })))

    var data = []
    var headers = []

    for (let res of responses) {
        if (res.headers.has('access-token') && res.status !== 304) {
            updateAccessToken(res.headers.get('access-token'))
        }
        let json = await res.json().catch(err => {
            return {};
        })
        data.push(json)
        headers.push(res.headers)
    }

    onSuccess(data, headers)

    // await Promise.all( urls.map( url =>  fetch(`${apiUrl}${url}`,  {
    //     headers : fetchHeaders()
    // }))).then( (responses) => {
    //     for(let res of responses) {
    //         if (res.headers.has('access-token') && res.status !== 304) {
    //             updateAccessToken(res.headers.get('access-token'))
    //         }
    //     }
    //     return Promise.all(responses.map( (response) => response.json().catch(err => {
    //         return {};
    //     })))
    // }).then( (data) => {
    //     onSuccess(data)
    // }).catch((error) => {
    //     onError(error)
    // })
}

export const validateGoogleLoginToken = (url, config, onSuccess, onError) => {
    const newHeaders = new Headers(config.headers)


    fetch((`${apiUrl}${url}`), {
        method : 'GET',
        headers : newHeaders
    })
        .then(response => {
            const auth_headers = {
                client: response.headers.get('client'),
                "access-token": response.headers.get('access-token'),
                uid: response.headers.get('uid'),
                expiry: response.headers.get('expiry'),
                "token-type": "Bearer"
            }

            localStorage.setItem('@portalmec/auth_headers', JSON.stringify(auth_headers))
            localStorage.setItem('@portalmec/accessToken', auth_headers["access-token"])

            return response.json().catch(err => {
                return {};
            })
        })
        .then(data => {
            onSuccess(data)
        })
        .catch(error => {
            onError(error)
        })
}
export async function authentication_signUp (url, payload, onSuccess, onError) {
    let formData = new FormData()
    for (const [key, value] of Object.entries(payload)) {
        formData.append(`${key}`,value);
    }

    let response = await fetch((`${apiUrl}${url}`), {
        method : 'POST',
        body: formData
    })
    if (response.ok) {
        onSuccess()
    }
    else {
        onError(response.status)
    }
}

export async function authentication (url, payload, onSuccess, onError) {
    console.log(payload)
    console.log(Object.entries(payload))
    let formData = new FormData()
    for (const [key, value] of Object.entries(payload)) {
        formData.append(`${key}`,value);
    }

    console.log(formData);
    let response = await fetch((`${apiUrl}${url}`), {
        method : 'POST',
        body: formData
    })
    if (response.ok) {
        const auth_headers = {
            client: response.headers.get('client'),
            "access-token": response.headers.get('access-token'),
            uid: response.headers.get('uid'),
            expiry: response.headers.get('expiry'),
            "token-type": "Bearer"
        }

        localStorage.setItem('@portalmec/auth_headers', JSON.stringify(auth_headers))
        localStorage.setItem('@portalmec/accessToken', auth_headers["access-token"])

        let json = await response.json().catch(err => {
            return {};
        })
        onSuccess(json)
    }
    else {
        onError(response.status)
    }
        // .then(response => {
        //     const auth_headers = {
        //         client: response.headers.get('client'),
        //         "access-token": response.headers.get('access-token'),
        //         uid: response.headers.get('uid'),
        //         expiry: response.headers.get('expiry'),
        //         "token-type": "Bearer"
        //     }
        //
        //     localStorage.setItem('@portalmec/auth_headers', JSON.stringify(auth_headers))
        //
        //     return response.json().catch(err => {
        //         return {};
        //     })
        // })
        // .then(data => {
        //     console.log(data)
        //     onSuccess(data)
        // })
        // .catch(error => {
        //     onError(error)
        // })

}
