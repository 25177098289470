/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useState, useContext } from 'react'
//imports material ui componets
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardAction from '@material-ui/core/CardActions'
import { Typography, TextField, Button, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import Chip from '@material-ui/core/Chip';
import MenuItem from "@material-ui/core/MenuItem"
//imports local files
import SnackBar from '../../../../Components/SnackbarComponent'
import { Store } from '../../../../Store'
import Unauthorized from '../Unauthorized'
import { postRequest } from "../../../../Components/HelperFunctions/getAxiosConfig"
//router
import { Link } from 'react-router-dom'


const CreateRequirement = () => {
  const { state } = useContext(Store)

  const [description, setDescription] = useState('')
  const [goal, setGoal] = useState('')
  const [resettable, setResettable] = useState('')
  const [actionId, setActionId] = useState('')
  const [achievements, setAchievements] = useState([])
  const [stringReq, setStringReq] = useState("")

  const [isLoading, setIsLoading] = useState(false)

  const [errorInGoal, setErrorInGoal] = useState({
    error: false,
    message: '',
  })
  const [errorInDescription, setErrorInDescription] = useState({
    error: false,
    message: '',
  })
  const [errorInActionId, setErrorInActionId] = useState({
    error: false,
    message: '',
  })

  const [snackInfo, setSnackInfo] = useState({
    message: '',
    icon: '',
    open: false,
    color: '',
  })

  const resettableOptions = [
    { name: 1, value: "Sim" },
    { name: 0, value: "Não" },

  ]

  const DescriptionHandler = (e) => {
    if (errorInDescription.error)
      setErrorInDescription({
        error: false,
        message: ''
      })
    setDescription(e.target.value)
  }
  const goalHandler = (e) => {
    if (errorInGoal.error)
      setErrorInGoal({
        error: false,
        message: ''
      })
    setGoal(e.target.value)
  }
  const actionIdHandler = (e) => {
    if (errorInActionId.error)
      setErrorInActionId({
        error: false,
        message: ''
      })
    setActionId(e.target.value)
  };

  const resettableHandler = (e) => {
    setResettable(e.target.value)
  }
  const stringReqHandler = (e) => {
    setStringReq(e.target.value)
  };

  // Handle snack infos
  const HandleSnack = (message, state, icon, color) => {
    setSnackInfo({
      message: message,
      icon: icon,
      open: state,
      color: color
    })
  }

  const CheckUserPermission = () => {
    let canUserEdit = false

    if (state.userIsLoggedIn) {
      const roles = [...state.currentUser.roles]
      for (let i = 0; i < roles.length; i++)
        if (roles[i].name === 'admin' || roles[i].name === 'editor')
          canUserEdit = true
    }
    else {
      canUserEdit = false
    }

    return canUserEdit
  }

  //Handle submit 
  async function onSubmit() {
    setIsLoading(true)
    const api = `/requirements`
    const body = {
      "requirement": {
        "description": description,
        "goal": goal,
        "repeatable": resettable,
        "action_id": parseInt(actionId),
        "achievements": achievements
      }
    }
    postRequest(
      api,
      body,
      (data) => {
        if (data.id)
          HandleSnack('O requisito foi criado com sucesso!', true, 'success', '#228B22')
        else {
          if (data.errors) {
            HandleSnack(`${data.errors[0]}`, true, 'warning', '#FA8072')
          }
          if (data.goal) {
            let goalError = ""
            data.goal.map((msg) => (
              goalError = goalError + msg + " e "
            ))
            setErrorInGoal({
              error: true,
              message: goalError
            })
          }
          if (data.description) {
            let descriptionError = ""
            data.description.map((msg) => (
              descriptionError = descriptionError + msg + " e "
            ))
            setErrorInDescription({
              error: true,
              message: descriptionError
            })
          }
          if (data.action) {
            let actionError = ""
            data.action.map((msg) => (
              actionError = actionError + msg + " e "
            ))
            setErrorInActionId({
              error: true,
              message: actionError
            })
          }
        }
        setIsLoading(false)
      },
      (error) => {
        HandleSnack('Ocorreu algum erro', true, 'warning', '#FA8072')
        setIsLoading(false)
      }
    )
  }

  const HandleDelete = (i) => {
    const copyReq = [...achievements];
    copyReq.splice(i, 1);
    setAchievements(copyReq);
  };

  const OnKeyPressHandler = (key) => {
    if (key === 13) {
      const copyReq = [...achievements];
      copyReq.push(parseInt(stringReq));
      setAchievements(copyReq);
      setStringReq("");
    }
  };

  // Fields
  const fields = [
    {
      select: false,
      label: 'Descrição',
      value: description,
      required: false,
      error: errorInDescription.error,
      errorMessage: errorInDescription.message,
      onChange: (event) => DescriptionHandler(event)
    },
    {
      select: false,
      label: 'Meta',
      value: goal,
      error: errorInGoal.error,
      errorMessage: errorInGoal.message,
      required: false,
      onChange: (event) => goalHandler(event)
    },
    {
      select: false,
      label: 'ID da ação',
      value: actionId,
      error: errorInActionId.error,
      errorMessage: errorInActionId.message,
      required: false,
      onChange: (event) => actionIdHandler(event)
    },
    {
      select: true,
      label: 'Repetível',
      value: resettable,
      required: false,
      options: [...resettableOptions],
      onChange: (event) => resettableHandler(event)
    },
  ]

  if (CheckUserPermission()) {
    return (
      <Card>
        <SnackBar
          severity={snackInfo.icon}
          text={snackInfo.message}
          snackbarOpen={snackInfo.open}
          color={snackInfo.color}
          handleClose={() => setSnackInfo({
            message: '',
            icon: '',
            open: false,
            color: ''
          })}
        />
        <CardContent>
          <Grid container direction='row' justify='space-between' alignContent="center" alignItems="center" xs={12}>
            <Grid item>
              <Typography variant='h4'>
                Criar requisito
              </Typography>
            </Grid>
            <Grid item>
              <Link style={{ textDecoration: 'none' }} to={'/admin/requirements'}>
                <Button
                  // onClick={props.BackToList}
                  startIcon={<ListRoundedIcon />}
                  variant='outlined'
                  color='primary'
                >
                  Listar
                </Button>
              </Link>
            </Grid>
          </Grid>

          <div style={{ height: '1em' }}></div>
          <form style={{ display: 'flex', flexDirection: 'column' }}>
            {fields.map((field, index) => (
              field.select ?
                <TextField
                  select
                  key={index}
                  required={field.required}
                  error={field.error}
                  helperText={field.error ? field.errorMessage : ''}
                  style={{ width: '250px', marginBottom: '1em' }}
                  label={field.label}
                  value={field.value}
                  onChange={field.onChange}
                  type="search"
                  multiline={true}
                >
                  {field.options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      value={option.name}
                      name={option.value}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
                :
                <TextField
                  key={index}
                  required={field.required}
                  error={field.error}
                  helperText={field.error ? field.errorMessage : ''}
                  style={{ width: '250px', marginBottom: '1em' }}
                  label={field.label}
                  value={field.value}
                  onChange={field.onChange}
                  type="search"
                  multiline={true}
                />
            ))}
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginBottom: "1em"
                }}
              >
                {achievements.map((req, index) => (
                  <li key={req.id} style={{ listStyleType: "none", marginBottom: "0.5em", marginRight: "0.5em" }}>
                    <Chip
                      label={req}
                      onDelete={() => HandleDelete(index)}
                    />
                  </li>
                ))}
              </div>

              <TextField
                id="outlined-input"
                label="Requisitos"
                rows={1}
                value={stringReq}
                onKeyPress={(key) => OnKeyPressHandler(key.which)}
                onChange={stringReqHandler}
                // onBlur={ShowEmails}
                helperText="Digite o ID do requisito, um por vez, e pressione Enter"
                style={{ marginBottom: "1em", width: '250px' }}
              />
            </>
          </form>
        </CardContent>
        <CardAction>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading ? null : <AddRoundedIcon />}
          >
            {
              isLoading ? <CircularProgress size={24} /> : 'Criar'
            }
          </Button>
        </CardAction>
      </Card>
    )
  } else return <Unauthorized />
}

export default CreateRequirement