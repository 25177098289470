/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Unauthorized from "../../../Components/Components/Unauthorized";
import { Store } from "../../../../Store";
import { Link, useNavigate } from "react-router-dom";
import { getRequest, deleteRequest, putRequest } from "../../../../Components/HelperFunctions/getAxiosConfig";
import { Url, DeleteFilter, EditFilter } from "../../../Filters";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import { withStyles } from "@material-ui/core/styles";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LoadingSpinner from "../../../../Components/LoadingSpinner";
import PageHeader from "../../../Components/Components/PageHeader";
import SnackBar from "../../../../Components/SnackbarComponent";
import TableData from "../../../Components/Components/Table";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import MobilePageHeader from "../../../Components/Components/MobileComponents/MobilePageHeader"
import styled from 'styled-components'
import MobileList from "../../../Components/Components/MobileComponents/SimpleList"
import { Grid, Typography } from "@material-ui/core";
import AlertDialog from "../../../Components/Components/AlertDialog"
import { GiAchievement } from 'react-icons/gi'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const GameficationAchieves = () => {
  const navigate = useNavigate()
  const WINDOW_WIDTH = window.innerWidth
  const ADD_ONE_LENGHT = [""];
  const TOP_LABELS = [
    "ESTADO",
    "ID",
    "NAME",
    "CRIADO EM",
    "ATUALIZADO EM",
    "AÇÕES",
    "VISUALIZAR"
  ]; //Labels from Table
  const { state } = useContext(Store);
  const [currPage, setcurrPage] = useState(0);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [items, setItems] = useState([]);
  const [stateOpt, setStateOpt] = useState(1)
  const [name, setName] = useState("")
  const [valueNameField, setValueNameField] = useState("")
  const [open, setOpen] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    icon: "",
    open: false,
    color: "",
  });

  const stateOptions = [
    { name: 0, value: "Inativo" },
    { name: 1, value: "Ativo" },
    { name: 2, value: "Removido" },
  ];

  //handle snack info
  const HandleSnack = (message, state, icon, color) => {
    setSnackInfo({
      message: message,
      icon: icon,
      open: state,
      color: color,
    });
  };

  const CheckUserPermission = () => {
    let canUserEdit = false;

    if (state.userIsLoggedIn) {
      const roles = [...state.currentUser.roles];
      for (let i = 0; i < roles.length; i++)
        if (roles[i].name === "admin" || roles[i].name === "editor")
          canUserEdit = true;
    } else {
      canUserEdit = false;
    }

    return canUserEdit;
  };

  const DisplayDate = (date) => {
    const convertedData = moment.utc(date);
    return moment(convertedData)
      .format("LLL")
      .toString();
  };

  const FastActions = (item, index) => {
    switch (item.state) {
      case "deleted":
        return (
          <Grid container alignItems="center" justify={WINDOW_WIDTH <= 950 ? "flex-start" : "flex-end"} spacing={1}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                style={{ backgroundColor: "#FF8C00", color: "#FFFAFA", width: "150px" }}
                onClick={() => inactiveItem(item, index)}
              >
                Inativar
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                style={{ backgroundColor: "#228B22", color: "#FFFAFA", width: "150px" }}
                variant="contained"
                onClick={() => activeItem(item, index)}
              >
                Ativar
                            </Button>
            </Grid>
          </Grid>
        );
      case "inactive":
        return (
          <Grid container alignItems="center" justify={WINDOW_WIDTH <= 950 ? "flex-start" : "flex-end"} spacing={1}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                style={{ backgroundColor: "#FA8072", color: "#FFFAFA", width: "150px" }}
                onClick={() => handleAlertDialog(item)}
              >
                Remover
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                style={{ backgroundColor: "#228B22", color: "#FFFAFA", width: "150px" }}
                variant="contained"
                onClick={() => activeItem(item, index)}
              >
                Ativar
                            </Button>
            </Grid>
          </Grid>
        );
      case "active":
        return (
          <Grid container alignItems="center" justify={WINDOW_WIDTH <= 950 ? "flex-start" : "flex-end"} spacing={1}>
            <Grid item>
              <Button
                style={{ backgroundColor: "#FA8072", color: "#FFFAFA", width: "150px" }}
                variant="contained"
                onClick={() => handleAlertDialog(item)}
              >
                Remover
                            </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ backgroundColor: "#FF8C00", color: "#FFFAFA", width: "150px" }}
                variant="contained"
                onClick={() => inactiveItem(item, index)}
              >
                Inativar
                            </Button>
            </Grid>
          </Grid>
        );
      default:
        return "NOTHING";
    }
  };

  const StateItem = (state) => {
    switch (state) {
      case "deleted":
        return (
          <Paper
            style={{
              textAlign: "center",
              padding: "0.5em",
              backgroundColor: "#FA8072",
              fontWeight: "500",
              color: "#FFFAFA",
            }}
          >
            REMOVIDO
          </Paper>
        );
      case "inactive":
        return (
          <Paper
            style={{
              textAlign: "center",
              padding: "0.5em",
              backgroundColor: "#FF8C00",
              fontWeight: "500",
              color: "#FFFAFA",
            }}
          >
            INATIVO
          </Paper>
        );
      case "active":
        return (
          <Paper
            style={{
              textAlign: "center",
              padding: "0.5em",
              backgroundColor: "#228B22",
              fontWeight: "500",
              color: "#FFFAFA",
            }}
          >
            ATIVO
          </Paper>
        );
      default:
        return "NOTHING";
    }
  };

  const handleAlertDialog = (item) => {
    setOpen(true)
    setDeleteItem(item)
  }

  const findIndexOfWantedItem = (item) => {
    const index = items.findIndex((item) => item.id === deleteItem.id)
    return index;
  }

  const deleteHandler = () => {
    deleteRequest(
      DeleteFilter("achievements", deleteItem.id),
      (data) => {
        if (data.errors)
          HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
        else {
          HandleSnack(
            "O achievement foi deletada com sucesso",
            true,
            "success",
            "#228B22"
          );
          handleChangeStateItem(findIndexOfWantedItem(deleteItem), "deleted")
        }
        setcurrPage(0)
      },
      (error) => {
        HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
      }
    )
  }

  const inactiveItem = (item, index) => {
    const body = {
      "achievement": {
        "state": "inactive"
      }
    }
    putRequest(
      EditFilter("achievements", item.id),
      body,
      (data) => {
        if (data.errors)
          HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
        else {
          HandleSnack(
            "O achievement foi inativado com sucesso",
            true,
            "success",
            "#228B22"
          );
          handleChangeStateItem(index, "inactive")
        }
      },
      (error) => {
        HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
      }
    )
  }

  const activeItem = (item, index) => {
    const body = {
      "achievement": {
        "state": "active"
      }
    }
    putRequest(
      EditFilter("achievements", item.id),
      body,
      (data) => {
        if (data.errors)
          HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
        else {
          HandleSnack(
            "O achievement foi ativado com sucesso",
            true,
            "success",
            "#228B22"
          );
          handleChangeStateItem(index, "active")
        }
      },
      (error) => {
        HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
      }
    )
  }

  const handleChangeStateItem = (index, state) => {
    const currItems = [...items]
    currItems.splice(index, 1)
    setItems(currItems)
  }

  const buildUrl = (name, state) => {
    if (name && (state >= 0 && state <= 2))
      return Url("achievements", `"state" : ${stateOpt}, "name" : "${name}"`, currPage, "DESC")
    else if (name)
      return Url("achievements", `"name" : "${name}"`, currPage, "DESC")
    else if (state >= 0 && state <= 2)
      return Url("achievements", `"state" : ${stateOpt}`, currPage, "DESC")
    else
      return Url("achievements", "", currPage, "DESC")
  }

  useEffect(() => {
    if (currPage === 0)
      setLoaded(false)
    else
      setIsLoadingMoreItems(true)
    getRequest(
      buildUrl(name, stateOpt),
      (data, header) => {
        const arrData = [...data]
        if (arrData.length === 0) {
          HandleSnack('Não há mais dados para serem carregados', true, 'warning', '#FFC125')
        } else {
          const arrItems = [...items]
          if (currPage === 0) {
            setItems(arrData.concat(ADD_ONE_LENGHT))
          }
          else {
            arrItems.pop(); //Deleting the last position, that was used to display the button of load more items 
            const arrResult = arrItems.concat(arrData)
            setItems(arrResult.concat(ADD_ONE_LENGHT))
          }
        }
        setLoaded(true)
        setIsLoadingMoreItems(false)
      },
      (error) => {
        HandleSnack('Erro ao carregar os dados', true, 'warning', '#FA8072')
        setIsLoadingMoreItems(false)
        setLoaded(true)
        setError(true)
      }
    )
  }, [currPage, name, stateOpt])

  useEffect(() => {
    setName("")
    setStateOpt(1)
  }, [showFilter])

  if (error) {
    return <div>Error</div>;
  } else if (!loaded) {
    return <LoadingSpinner text="Carregando..." />;
  } else {
    if (CheckUserPermission()) {
      if (WINDOW_WIDTH <= 950) {
        return (
          <>
            <AlertDialog
              open={open}
              OnDelete={deleteHandler}
              deleteItem={deleteItem}
              HandleClose={() => {
                setOpen(false)
              }}
            />
            <SnackBar
              severity={snackInfo.icon}
              text={snackInfo.message}
              snackbarOpen={snackInfo.open}
              color={snackInfo.color}
              handleClose={() =>
                setSnackInfo({
                  message: "",
                  icon: "",
                  open: false,
                  color: "",
                })
              }
            />
            <MobilePageHeader
              title="Conquistas"
              actions={[
                {
                  name: "Atualizar",
                  isLoading: false,
                  func: () => {
                    setcurrPage(0);
                  },
                  icon: <UpdateRoundedIcon />,
                },
                {
                  name: "Filtrar",
                  isLoading: false,
                  func: () => {
                    setShowFilter(!showFilter);
                  },
                  icon: <FilterListRoundedIcon />,
                },
                {
                  name: "Novo",
                  isLoading: false,
                  func: () => {
                    navigate("/admin/createAchievements")
                  },
                  icon: <AddRoundedIcon />,
                },
              ]}
            >
              {showFilter ? (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  alignContent="flex-end"
                  spacing={3}
                  xs={12}
                >
                  <Grid item>
                    <TextField
                      select
                      label="Estado"
                      value={stateOpt}
                      onChange={(e) => { setStateOpt(e.target.value) }}
                      helperText="Por favor, selecione uma das opções"
                    >
                      {stateOptions.map((option, index) => (
                        <MenuItem
                          key={option.value}
                          value={option.name}
                          name={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Nome"
                      value={valueNameField}
                      onChange={(e) => { setValueNameField(e.target.value) }}
                      onBlur={(e) => { setName(e.target.value) }}
                      helperText="Por favor, ao digitar o nome que você quer filtar, retire o foco do campo de texto"
                    />
                  </Grid>
                </Grid>
              ) : null}
            </MobilePageHeader>
            <div style={{ height: '2em' }}></div>

            {items.map((row, index) =>
              index === items.length - 1 ? (
                <StyledDivButton>
                  <Button
                    key={index}
                    color="primary"
                    variant="text"
                    // disabled={isLoadingMoreItems}
                    startIcon={<AddRoundedIcon />}
                    disabled={isLoadingMoreItems}
                    onClick={() => {
                      setcurrPage(currPage + 1)
                    }}
                  >
                    {isLoadingMoreItems ? (
                      <CircularProgress size={24} />
                    ) : (
                        "Carregar mais itens"
                      )}
                  </Button>
                </StyledDivButton>
              ) : (
                  <>
                    <MobileList
                      key={index}
                      title={row.name}
                      subtitle={row.id}
                      backColor={"#e81f4f"}
                      avatar={
                        <GiAchievement size={20} color="white" />

                      }
                      href={`/admin/achievement/${row.id}`}
                      reset={() => { }}
                      data={[

                        {
                          title: "Criado em",
                          subtitle: DisplayDate(row.created_at)
                        },
                        {
                          title: "Atualizado em",
                          subtitle: DisplayDate(row.updated_at)
                        },
                        {
                          title: "Experiência ganha",
                          subtitle: <Typography style={{ color: "#228B22" }}>
                            {"+" + (row.reward_experience) + " experiência"}
                          </Typography>
                        },
                        {
                          title: "Points ganho",
                          subtitle: <Typography style={{ color: "#228B22" }}>
                            {"+" + (row.reward_points) + " points"}
                          </Typography>
                        },
                        {
                          title: "Descrição",
                          subtitle: row.description
                        },
                        {
                          title: "Requisitos",
                          subtitle: <ul>
                            {
                              row.requirements.map((req) => {
                                return (
                                  <li key={req.created_at}>
                                    {req.description}
                                  </li>
                                )
                              })
                            }
                          </ul>
                        },
                        {
                          title: "Estado",
                          subtitle: StateItem(row.state)
                        },
                        {
                          title: "Ações rapidos",
                          subtitle: FastActions(row, index)
                        },
                      ]}
                    />
                    <div style={{ height: "0.5em" }} />
                  </>
                )
            )}
          </>
        )
      } else {
        return (
          <>
            <SnackBar
              severity={snackInfo.icon}
              text={snackInfo.message}
              snackbarOpen={snackInfo.open}
              color={snackInfo.color}
              handleClose={() =>
                setSnackInfo({
                  message: "",
                  icon: "",
                  open: false,
                  color: "",
                })
              }
            />
            <PageHeader
              title="Conquistas"
              actions={[
                {
                  name: "Atualizar",
                  isLoading: false,
                  func: () => {
                    setcurrPage(0);
                  },
                  icon: <UpdateRoundedIcon />,
                },
                {
                  name: "Filtrar",
                  isLoading: false,
                  func: () => {
                    setShowFilter(!showFilter);
                  },
                  icon: <FilterListRoundedIcon />,
                },
                {
                  name: "Novo",
                  isLoading: false,
                  func: () => {
                    navigate("/admin/createAchievements")
                  },
                  icon: <AddRoundedIcon />,
                },
              ]}
            >
              {showFilter ? (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  alignContent="flex-end"
                  spacing={3}
                  xs={12}
                >
                  <Grid item>
                    <TextField
                      select
                      label="Estado"
                      value={stateOpt}
                      onChange={(e) => { setStateOpt(e.target.value) }}
                      helperText="Por favor, selecione uma das opções"
                    >
                      {stateOptions.map((option, index) => (
                        <MenuItem
                          key={option.value}
                          value={option.name}
                          name={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Nome"
                      value={valueNameField}
                      onChange={(e) => { setValueNameField(e.target.value) }}
                      onBlur={(e) => { setName(e.target.value) }}
                      helperText="Por favor, ao digitar o nome que você quer filtar, retire o foco do campo de texto"
                    />
                  </Grid>
                </Grid>
              ) : null}
            </PageHeader>

            <div style={{ height: "2em" }}></div>

            <TableData top={TOP_LABELS}>
              <TableBody>
                {items.map((row, index) =>
                  index === items.length - 1 ? (
                    <StyledTableRow key={index} style={{ padding: "1em" }}>
                      {/* Button to load more data */}
                      <StyledTableCell>
                        <Button
                          color="primary"
                          variant="text"
                          // disabled={isLoadingMoreItems}
                          startIcon={<AddRoundedIcon />}
                          disabled={isLoadingMoreItems}
                          onClick={() => {
                            setcurrPage(currPage + 1);
                          }}
                        >
                          {isLoadingMoreItems ? (
                            <CircularProgress size={24} />
                          ) : (
                              "Carregar mais itens"
                            )}
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {StateItem(row.state)}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.id}</StyledTableCell>
                        <StyledTableCell align="right">{row.name}</StyledTableCell>
                        <StyledTableCell align="right">
                          {DisplayDate(row.created_at)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {DisplayDate(row.updated_at)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {FastActions(row, index)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Link to={`/admin/achievement/${row.id}`}>
                            <IconButton>
                              <VisibilityIcon style={{ fill: "#00bcd4" }} />
                            </IconButton>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                )}
              </TableBody>
            </TableData>
            <AlertDialog
              open={open}
              OnDelete={deleteHandler}
              deleteItem={deleteItem}
              HandleClose={() => {
                setOpen(false)
              }}
            />
          </>
        );
      }
    } else return <Unauthorized />;
  }
};
export default GameficationAchieves;

const StyledDivButton = styled(Paper)`
    width : 100%;
    display : flex; 
    justify-content : center; 
    align-items : center; 
`


