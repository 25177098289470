/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Unauthorized from "../../../Components/Components/Unauthorized";
import { Store } from "../../../../Store";
import { Link, useNavigate } from "react-router-dom";
import { getRequest, deleteRequest } from "../../../../Components/HelperFunctions/getAxiosConfig";
import { Url, DeleteFilter } from "../../../Filters";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import { withStyles } from "@material-ui/core/styles";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LoadingSpinner from "../../../../Components/LoadingSpinner";
import PageHeader from "../../../Components/Components/PageHeader";
import SnackBar from "../../../../Components/SnackbarComponent";
import TableData from "../../../Components/Components/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import MobilePageHeader from "../../../Components/Components/MobileComponents/MobilePageHeader"
import styled from 'styled-components'
import MobileList from "../../../Components/Components/MobileComponents/SimpleList"
import { Grid } from "@material-ui/core";
import AlertDialog from "../../../Components/Components/AlertDialog"
import { DiRequirejs } from 'react-icons/di'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const GameficationRequires = () => {
  const navigate = useNavigate()
  const WINDOW_WIDTH = window.innerWidth
  const ADD_ONE_LENGHT = [""];
  const TOP_LABELS = [
    "ID",
    "NAME",
    "DESCRIÇÃO",
    "META",
    "REPETIVEL",
    "CRIADO EM",
    "ATUALIZADO EM",
    "AÇÕES"
  ]; //Labels from Table
  const { state } = useContext(Store);
  const [currPage, setcurrPage] = useState(0);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [items, setItems] = useState([]);
  const [stateOpt, setStateOpt] = useState(1)
  const [descricao, setDescricao] = useState("")
  const [valueDescricaoField, setValueDescricaoField] = useState("")
  const [open, setOpen] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    icon: "",
    open: false,
    color: "",
  });

  //handle snack info
  const HandleSnack = (message, state, icon, color) => {
    setSnackInfo({
      message: message,
      icon: icon,
      open: state,
      color: color,
    });
  };

  const CheckUserPermission = () => {
    let canUserEdit = false;

    if (state.userIsLoggedIn) {
      const roles = [...state.currentUser.roles];
      for (let i = 0; i < roles.length; i++)
        if (roles[i].name === "admin" || roles[i].name === "editor")
          canUserEdit = true;
    } else {
      canUserEdit = false;
    }

    return canUserEdit;
  };

  const DisplayDate = (date) => {
    const convertedData = moment.utc(date);
    return moment(convertedData)
      .format("LLL")
      .toString();
  };

  const handleAlertDialog = (item) => {
    setOpen(true)
    setDeleteItem(item)
  }

  const findIndexOfWantedItem = (item) => {
    const index = items.findIndex((item) => item.id === deleteItem.id)
    return index;
  }

  const deleteHandler = () => {
    deleteRequest(
      DeleteFilter("requirements", deleteItem.id),
      (data) => {
        if (data.errors)
          HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
        else {
          HandleSnack(
            "O achievement foi deletada com sucesso",
            true,
            "success",
            "#228B22"
          );
          handleChangeStateItem(findIndexOfWantedItem(deleteItem), "deleted")
        }
        setcurrPage(0)
      },
      (error) => {
        HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
      }
    )
  }

  const handleChangeStateItem = (index, state) => {
    const currItems = [...items]
    currItems.splice(index, 1)
    setItems(currItems)
  }

  const buildUrl = (description) => {
    if (description)
      return Url("requirements", `"description" : "${description}"`, currPage, "DESC")
    else
      return Url("requirements", "", currPage, "DESC")
  }

  useEffect(() => {
    if (currPage === 0)
      setLoaded(false)
    else
      setIsLoadingMoreItems(true)
    getRequest(
      buildUrl(descricao),
      (data, header) => {
        const arrData = [...data]
        if (arrData.length === 0) {
          HandleSnack('Não há mais dados para serem carregados', true, 'warning', '#FFC125')
        } else {
          const arrItems = [...items]
          if (currPage === 0) {
            setItems(arrData.concat(ADD_ONE_LENGHT))
          }
          else {
            arrItems.pop(); //Deleting the last position, that was used to display the button of load more items 
            const arrResult = arrItems.concat(arrData)
            setItems(arrResult.concat(ADD_ONE_LENGHT))
          }
        }
        setLoaded(true)
        setIsLoadingMoreItems(false)
      },
      (error) => {
        HandleSnack('Erro ao carregar os dados', true, 'warning', '#FA8072')
        setIsLoadingMoreItems(false)
        setLoaded(true)
        setError(true)
      }
    )
  }, [currPage, descricao, stateOpt])

  useEffect(() => {
    setDescricao("")
    setStateOpt(1)
  }, [showFilter])

  if (error) {
    return <div>Error</div>;
  } else if (!loaded) {
    return <LoadingSpinner text="Carregando..." />;
  } else {
    if (CheckUserPermission()) {
      if (WINDOW_WIDTH <= 950) {
        return (
          <>
            <AlertDialog
              open={open}
              OnDelete={deleteHandler}
              deleteItem={deleteItem}
              HandleClose={() => {
                setOpen(false)
              }}
            />
            <SnackBar
              severity={snackInfo.icon}
              text={snackInfo.message}
              snackbarOpen={snackInfo.open}
              color={snackInfo.color}
              handleClose={() =>
                setSnackInfo({
                  message: "",
                  icon: "",
                  open: false,
                  color: "",
                })
              }
            />
            <MobilePageHeader
              title="Requisitos"
              actions={[
                {
                  name: "Atualizar",
                  isLoading: false,
                  func: () => {
                    setcurrPage(0);
                  },
                  icon: <UpdateRoundedIcon />,
                },
                {
                  name: "Filtrar",
                  isLoading: false,
                  func: () => {
                    setShowFilter(!showFilter);
                  },
                  icon: <FilterListRoundedIcon />,
                },
                {
                  name: "Novo",
                  isLoading: false,
                  func: () => {
                    navigate("/admin/CreateRequirement")
                  },
                  icon: <AddRoundedIcon />,
                },
              ]}
            >
              {showFilter ? (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  alignContent="flex-end"
                  spacing={3}
                  xs={12}
                >
                  <Grid item>
                    <TextField
                      label="Descrição"
                      value={valueDescricaoField}
                      onChange={(e) => { setValueDescricaoField(e.target.value) }}
                      onBlur={(e) => { setDescricao(e.target.value) }}
                      helperText="Por favor, ao digitar o descrição que você quer filtar, retire o foco do campo de texto"
                    />
                  </Grid>
                </Grid>
              ) : null}
            </MobilePageHeader>
            <div style={{ height: '2em' }}></div>

            {items.map((row, index) =>
              index === items.length - 1 ? (
                <StyledDivButton>
                  <Button
                    key={index}
                    color="primary"
                    variant="text"
                    // disabled={isLoadingMoreItems}
                    startIcon={<AddRoundedIcon />}
                    disabled={isLoadingMoreItems}
                    onClick={() => {
                      setcurrPage(currPage + 1)
                    }}
                  >
                    {isLoadingMoreItems ? (
                      <CircularProgress size={24} />
                    ) : (
                        "Carregar mais itens"
                      )}
                  </Button>
                </StyledDivButton>
              ) : (
                  <>
                    <MobileList
                      key={index}
                      title={row.action.name}
                      subtitle={row.id}
                      backColor={"#673ab7"}
                      avatar={
                        <DiRequirejs size={20} color="white" />
                      }
                      href={`/admin/requirement/${row.id}`}
                      reset={() => { }}
                      data={[
                        {
                          title: "Criado em",
                          subtitle: DisplayDate(row.created_at)
                        },
                        {
                          title: "Atualizado em",
                          subtitle: DisplayDate(row.updated_at)
                        },
                        {
                          title: "Descrição",
                          subtitle: row.description
                        },
                        {
                          title: "Achivements que depende desse requisito",
                          subtitle: <ul>
                            {
                              row.achievements.map((achieve) => {
                                return (
                                  <li key={achieve.created_at}>
                                    <Link to={`/admin/achievement/${achieve.id}`}>
                                      <span style={{textDecoration: "none", color: '#673ab7', backgroundColor:'none'}}>
                                        {achieve.name}
                                      </span>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        },
                        {
                          title: "Ações",
                          subtitle: <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteRoundedIcon />}
                            onClick={() => handleAlertDialog(row)}
                          >
                            Deletar
                          </Button>
                        },
                      ]}
                    />
                    <div style={{ height: "0.5em" }} />
                  </>
                )
            )}
          </>
        )
      } else {
        return (
          <>
            <SnackBar
              severity={snackInfo.icon}
              text={snackInfo.message}
              snackbarOpen={snackInfo.open}
              color={snackInfo.color}
              handleClose={() =>
                setSnackInfo({
                  message: "",
                  icon: "",
                  open: false,
                  color: "",
                })
              }
            />
            <PageHeader
              title="Requisitos"
              actions={[
                {
                  name: "Atualizar",
                  isLoading: false,
                  func: () => {
                    setcurrPage(0);
                  },
                  icon: <UpdateRoundedIcon />,
                },
                {
                  name: "Filtrar",
                  isLoading: false,
                  func: () => {
                    setShowFilter(!showFilter);
                  },
                  icon: <FilterListRoundedIcon />,
                },
                {
                  name: "Novo",
                  isLoading: false,
                  func: () => {
                    navigate("/admin/CreateRequirement")
                  },
                  icon: <AddRoundedIcon />,
                },
              ]}
            >
              {showFilter ? (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  alignContent="flex-end"
                  spacing={3}
                  xs={12}
                >
                  <Grid item>
                    <TextField
                      label="Descrição"
                      value={valueDescricaoField}
                      onChange={(e) => { setValueDescricaoField(e.target.value) }}
                      onBlur={(e) => { setDescricao(e.target.value) }}
                      helperText="Por favor, ao digitar o descrição que você quer filtar, retire o foco do campo de texto"
                    />
                  </Grid>
                </Grid>
              ) : null}
            </PageHeader>

            <div style={{ height: "2em" }}></div>

            <TableData top={TOP_LABELS}>
              <TableBody>
                {items.map((row, index) =>
                  index === items.length - 1 ? (
                    <StyledTableRow key={index} style={{ padding: "1em" }}>
                      {/* Button to load more data */}
                      <StyledTableCell>
                        <Button
                          color="primary"
                          variant="text"
                          // disabled={isLoadingMoreItems}
                          startIcon={<AddRoundedIcon />}
                          disabled={isLoadingMoreItems}
                          onClick={() => {
                            setcurrPage(currPage + 1);
                          }}
                        >
                          {isLoadingMoreItems ? (
                            <CircularProgress size={24} />
                          ) : (
                              "Carregar mais itens"
                            )}
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.action.name}</StyledTableCell>
                        <StyledTableCell align="right">{row.description}</StyledTableCell>
                        <StyledTableCell align="right">{row.goal}</StyledTableCell>
                        <StyledTableCell align="right">{row.repeatable}</StyledTableCell>
                        <StyledTableCell align="right">
                          {DisplayDate(row.created_at)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {DisplayDate(row.updated_at)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Link to={`/admin/requirement/${row.id}`}>
                            <Button
                              style={{ width: "100%", marginBottom: "0.5em" }}
                              variant="contained"
                              color="primary"
                              startIcon={<VisibilityIcon />}
                            >
                              Visualizar
                            </Button>
                          </Link>

                          <Button
                            style={{ width: "100%" }}
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteRoundedIcon />}
                            onClick={() => handleAlertDialog(row)}
                          >
                            Deletar
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                )}
              </TableBody>
            </TableData>
            <AlertDialog
              open={open}
              OnDelete={deleteHandler}
              deleteItem={deleteItem}
              HandleClose={() => {
                setOpen(false)
              }}
            />
          </>
        );
      }
    } else return <Unauthorized />;
  }
};
export default GameficationRequires;

const StyledDivButton = styled(Paper)`
    width : 100%;
    display : flex; 
    justify-content : center; 
    align-items : center; 
`


