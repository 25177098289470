/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React from 'react'
import Grid from "@material-ui/core/Grid"
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function CookiesConsent(setUpdateShowBar,contrast){
    
    const mobileScreen = (window.innerWidth<=1000)

    const theme = createTheme({
        palette: {
            primary: {
            // This is green.A700 as hex.
            main: '#FFFFFF',
            },
            secondary : {
                main : '#000000'
            }
        },
    });
    
    const handleClick = () => {
        Cookies.set("showBar",false,{expires: 7},{sameSite:'strict'})
        setUpdateShowBar(true)
    }

    return(
            <Grid container direction={mobileScreen? "column" : "row"} alignItems='center'
                style={{
                width:"99.9%",position:"fixed",color:"white",
                backgroundColor:`${contrast?"black":"#673ab7"}`,
                borderStyle : "solid",
                borderWidth : `${contrast?"1px":"0px"}`,
                borderColor : `${contrast?"white":"black"}`,
                opacity:"1",visibility:"1",zIndex:"999",
                borderRadius:"0",bottom:"0",left:"0"}} 
            >
                <Grid item md={10} style={{textAlign : "center"}}>
                    <p>
                        Este site utiliza cookies temporários ao publicar recursos. Não salvamos nenhuma informação pessoal do usuário, apenas do recurso a ser publicado. Ao utilizar nossos serviços, você concorda com o uso dos cookies.
                    </p>
                </Grid>
                <Grid item md={2}>
                    <ThemeProvider theme={theme}>
                        <Button 
                            onClick={()=>handleClick()} variant = "contained" color = {contrast? "secondary" : "primary"} 
                            style={{color : `${contrast? "yellow" : "purple"}`, borderStyle : "solid" , 
                                    borderWidth :`${contrast?"1px":"0px"}`,
                                    borderColor : `${contrast?"white":"black"}`,
                                    fontStyle : "bold", fontSize : "16px",
                                    left:`${mobileScreen? "0px" : "20px"}`}}
                        >
                            Fechar
                        </Button>
                    </ThemeProvider>
                </Grid>
            </Grid>
        
    )

}