/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, {useState} from 'react';
import SnackBar from '../../../Components/SnackbarComponent'; 
import { Link } from 'react-router-dom';
import styled from "styled-components";

const Unauthorized = (props) => {

    const [openSnack, setOpenSnack] = useState(true);

    return (
        <ContentDiv contrast={props.contrast} style={{ textAlign: 'center' }}>
            <SnackBar
                severity='warning'
                text='Você não tem as permissões necessárias'
                snackbarOpen={openSnack}
                handleClose={() => setOpenSnack(false)}
            />
            <>
                <StyledDiv><span style={{ fontSize: '50px' }}>Desculpe</span></StyledDiv>
                <StyledDiv>
                    <span style={{ fontSize: '30px' }}>
                        Você não tem as permissões necessárias para acessar essa página.
                    </span>
                </StyledDiv>
                <StyledDiv>
                    <Link to='/'>
                        <span style={{ fontSize: '20px' }}>
                            Voltar para a página inicial
                        </span>
                    </Link>
                </StyledDiv>
            </>
        </ContentDiv>
    );
}

const ContentDiv = styled('div')`
    background-color: ${props => props.contrast === "" ? "white" : "black"} !important;
    color: ${props => props.contrast === "" ? "#666" : "white"} !important;
    a {
        color: ${props => props.contrast === "" ? "" : "yellow"} !important;
        text-decoration: ${props => props.contrast === "" ? "none" : "underline yellow"} !important;
    }
`

const StyledDiv = styled('div')`
    width: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    justify-content : space-evenly;
    display: flex;
    text-align:center;   
`

export default Unauthorized;