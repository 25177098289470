import React, { useContext } from 'react';
import { Store } from 'Store'

import LoadingGif from '../img/loading_busca.gif'

const LoadingSpinner = (props) => {
    const { state } = useContext(Store);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "inherit" }}>
            <img alt="" src={LoadingGif} />
            <span style={state.contrast === "" ? { textTransform: "uppercase" } : { textTransform: "uppercase", color: "white" }}>{props.text}</span>
        </div>
    )
}

export default LoadingSpinner;
