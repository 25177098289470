/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React, { useState, useEffect } from "react";
import moment from "moment";
// Maerial ui components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import { useStyles } from "../../Styles/DataCard";
import Grid from "@material-ui/core/Grid";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
// Icons
import EditRoundedIcon from "@material-ui/icons/EditRounded";
//imports from local files
import { GetAData, DeleteFilter } from "../../../Filters";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../Components/LoadingSpinner";
import SnackBar from "../../../../Components/SnackbarComponent";
import {
  getRequest,
  deleteRequest,
} from "../../../../Components/HelperFunctions/getAxiosConfig";
import styled from 'styled-components'
import { DiRequirejs } from 'react-icons/di'

const AchievementCard = ({ match }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const WINDOW_WIDTH = window.innerWidth
  const [error, setError] = useState(false) //Necessary to consult the API, catch errors
  const [isLoaded, setIsLoaded] = useState(false) //Necessary to consult the API, wait until complete
  const [item, setItem] = useState()
  const [reloadPage, setReloadPage] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    icon: "",
    open: false,
    color: "",
  });

  const DisplayDate = (date) => {
    const convertedData = moment.utc(date);
    return moment(convertedData)
      .format("LLL")
      .toString();
  };

  const HandleSnack = (message, state, icon, color) => {
    setSnackInfo({
      message: message,
      icon: icon,
      open: state,
      color: color,
    });
  };

  const deleteHandler = () => {
    deleteRequest(
      DeleteFilter("requirements", item.id),
      (data) => {
        if (data.errors)
          HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
        else {
          HandleSnack(
            "O item foi deletada com sucesso",
            true,
            "success",
            "#228B22"
          );
          setReloadPage(!reloadPage)
        }
        navigate(-1)
      },
      (error) => {
        HandleSnack("Ocorreu algum erro", true, "warning", "#FA8072");
      }
    )
  }

  useEffect(() => {
    setIsLoaded(false)
    getRequest(
      GetAData("requirements", match.params.id),
      (data, header) => {
        setItem(data);
        setIsLoaded(true);
        setError(false);
      },
      (error) => {
        setIsLoaded(true);
        setError(true);
      }
    );
  }, [reloadPage]);

  if (error) {
    return <div>Houve um erro</div>;
  } else if (!isLoaded) {
    return <LoadingSpinner text="Carregando..." />;
  } else {
    return (
      <>
        <SnackBar
          severity={snackInfo.icon}
          text={snackInfo.message}
          snackbarOpen={snackInfo.open}
          color={snackInfo.color}
          handleClose={() =>
            setSnackInfo({
              message: "",
              icon: "",
              open: false,
              color: "",
            })
          }
        />
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                  container
                >
                  <Grid item>
                    <Typography
                      className={classes.title}
                      color="inherit"
                      gutterBottom
                    >
                      Informações do requisito
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/admin/requirements`}
                    >
                      <Button
                        startIcon={<ListRoundedIcon />}
                        color="primary"
                        variant="outlined"
                      >
                        Listar
                      </Button>
                    </Link>
                    <Link
                      to={`/admin/EditRequirement/${match.params.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        startIcon={<EditRoundedIcon />}
                        color="primary"
                        variant="outlined"
                      >
                        Editar
                      </Button>
                    </Link>
                    <Button
                      startIcon={<DeleteRoundedIcon />}
                      color="secondary"
                      variant="outlined"
                      onClick={deleteHandler}
                    >
                      Deletar
                    </Button>
                  </Grid>
                </Grid>
                <div style={{ height: "1em" }} />
                <Grid container direction="row" spacing={3}>
                  <Grid item sm={5} xs={12} style={WINDOW_WIDTH >= 600 ? {
                    borderRight: "solid #d4d4d4 1px"
                  } : {
                      borderBottom: "solid #d4d4d4 1px"
                    }}>
                    <ImgDiv>
                      <DiRequirejs size={200} color="#673ab7" />
                    </ImgDiv>
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography
                          color="initial"
                          className={classes.subTitle}
                        >
                          Nome
                          </Typography>
                        <Link to={`/admin/action/${item.action.id}`}>
                          <span style={{ textDecoration: 'none', color: "#673ab7" }}>
                            {item.action.name}
                          </span>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="initial"
                          className={classes.subTitle}
                        >
                          Criado em
                                                </Typography>
                        <Typography color="textSecondary">
                          {DisplayDate(item.created_at)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="initial"
                          className={classes.subTitle}
                        >
                          Atualizado em
                                                </Typography>
                        <Typography color="textSecondary">
                          {DisplayDate(item.updated_at)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="initial" className={classes.subTitle}>
                          Descrição
                        </Typography>
                        <Typography color="textSecondary">
                          {item.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography color="initial" className={classes.subTitle}>
                      Achievements que dependem desse requisito
                    </Typography>
                    <Typography color="textSecondary">
                      <ul>
                        {
                          item.achievements.map((achieve) => {
                            return (
                              <li key={achieve.created_at}>
                                <Link to={`/admin/achievement/${achieve.id}`}>
                                  <span style={{ textDecoration: 'none', color: "#673ab7" }}>
                                    {achieve.name}
                                  </span>
                                </Link>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default AchievementCard;

const ImgDiv = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center;
  height: 100%; 
  width: 100%;
`
