/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React from 'react'

export const Store = React.createContext()

const initialState = {
    searchOpen: false,
    userIsLoggedIn: false,
    userAgreedToPublicationTerms: false,
    userAgreedToPublicationPermissions: false,
    modalColaborarPlataformaOpen: false,
    search: {
        query: '*',
        class: 'LearningObject'
    },
    windowSize: {
        width: 0,
        height: 0
    },
    currentUser: {
        askTeacherQuestion: false,
        id: '',
        name: '',
        email: '',
        avatar: '',
        cover: '',
        uid: '',
        follows_count: 0,
        collections_count: 0,
        submitter_request: 'default',
        roles: [],
    },
    contrast: '',
    fontSize: 15,
    reloadContactCard: false,
    reloadEditProfile: false,
    redirectFrontPage: false,

}



function reducer(state, action) {
    switch (action.type) {
        case 'SET_CONTRAST':
            return {
                ...state,
                contrast: action.payload
            };
        case 'SET_FONT_SIZE':
            return {
                ...state,
                fontSize: action.payload
            };
        case 'SAVE_SEARCH':
            return {
                ...state,
                search: action.newSearch
            }
        case 'HANDLE_SEARCH_BAR':
            return {
                ...state,
                searchOpen: action.opened
            }
        case 'WINDOW_SIZE':
            return {
                ...state,
                windowSize: action.innerWindow
            }
        case 'USER_LOGGED_IN':
            if (action.login.avatar_file_name) {
                Object.defineProperty(action.login, 'avatar',
                    Object.getOwnPropertyDescriptor(action.login, 'avatar_file_name'));
                delete action.login['avatar_file_name'];
            }
            if (action.login.cover_file_name) {
                Object.defineProperty(action.login, 'cover',
                    Object.getOwnPropertyDescriptor(action.login, 'cover_file_name'));
                delete action.login['cover_file_name'];
            }
            return {
                ...state,
                userIsLoggedIn: action.userLoggedIn,
                currentUser: action.login
            }
        case 'USER_LOGGED_OUT':
            localStorage.clear()
            return {
                ...state,
                userIsLoggedIn: action.userLoggedOut,
                currentUser: {
                    askTeacherQuestion: false,
                    id: '',
                    name: '',
                    email: '',
                    avatar_file_name: '',
                    cover_file_name: '',
                    uid: '',
                    follows_count: 0,
                    collections_count: 0,
                    submitter_request: 'default',
                    roles: [],
                    email_confirmed:false
                },
                userAgreedToPublicationTerms: false,
                userAgreedToPublicationPermissions: false
            }
        case 'USER_AGREED_TO_PUBLICATION_TERMS':
            return {
                ...state,
                userAgreedToPublicationTerms: action.userAgreement
            }
        case 'USER_AGREED_TO_PUBLICATION_PERMISSIONS':
            return {
                ...state,
                userAgreedToPublicationPermissions: action.userAgreement
            }
        case 'USER_ACCESSED_USER_PAGE':
            return {
                ...state,
                currentUser: action.set
            }
        case 'USER_CHANGED_COVER':
            return {
                ...state,
                currentUser: action.currUser
            }
        case 'USER_DELETED_ACCOUNT':
            localStorage.clear()
            return {
                ...state,
                userIsLoggedIn: false,
                currentUser: {
                    askTeacherQuestion: false,
                    id: '',
                    name: '',
                    email: '',
                    avatar_file_name: '',
                    cover_file_name: '',
                    uid: '',
                    follows_count: 0,
                    collections_count: 0,
                    submitter_request: 'default',
                    roles: []
                },
            }
        case 'TOGGLE_MODAL_COLABORAR_PLATAFORMA':
            return {
                ...state,
                modalColaborarPlataformaOpen: action.modalColaborarPlataformaOpen
            }
        case 'SET_USER':
            return {
                ...state,
                currentUser: action.user
            }
        case 'USER_UPDATED_EMAIL':
            return {
                ...state,
                currentUser: action.currUser
            }
        case 'RELOAD_CONTACT_CARD':
            return{
                ...state,
                reloadContactCard: action.reloadContactCard
            }
        case 'RELOAD_EDIT_PROFILE':
            return{
                ...state,
                reloadEditProfile: action.reloadEditProfile
            }
        case 'REDIRECT_FRONT_PAGE' :
            return{
                ...state,
                redirectFrontPage: action.redirectFrontPage
            }
        default:
            return state
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = { state, dispatch };
    return (
        <Store.Provider value={value}>
            {props.children}
        </Store.Provider>
    )
}
