/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

export function Url(type, filter, page , sort) {
    const api = `/${type}?filter={${filter}}&page=${page}&range=[0,9]&results_per_page=10&sort=["id","${sort}"]`
    return (api)
} 

export function GetOneOfAllUrl(type , filter){
    const api = `/${type}?filter={${filter}}`
    return (api)
}

export function MethodsToComplain(type , id, meth){
    const api = `/${type}/${id}/${meth}`;
    return (api)
}

export function newMethodsToComplain(type , id,pros,cons){
    const api = `/complaint_votes/${type}/${id}/${pros}/${cons}`;
    return (api)
}

export function GetAll(type){
    const api = `/${type}`
    return (api)
}


export function GetAData(type , id){
    const api = `/${type}/${id}`
    return (api)
}


export function EditFilter(type , id){
    const api = `/${type}/${id}` 
    return (api)
} 

export function DeleteFilter(type , id){
    const api = `/${type}/${id}` 
    return (api)
}