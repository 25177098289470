/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components'
import ComponentAddImage from './CompAddImg'

const StyledModal = styled(Modal)`
    display : flex;
    align-items: center;
    justify-content : center;
    text-align : center;
    padding : 10px !important;
`

export default function ModalAddImage (props){

    return (

        <StyledModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
             
            centered="true"
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
         >
            <Fade in={props.open} style={{ transitionDelay :"0.4ms"}}>

                <ComponentAddImage
                    userAvatar={props.userAvatar}
                    handleClose={props.handleClose}
                    handleComplete={props.handleComplete}
                    id={props.id}
                />
            </Fade>
        </StyledModal>

    )
}
