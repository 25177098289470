/*Copyright (C) 2019 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana

This file is part of Plataforma Integrada MEC.

Plataforma Integrada MEC is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Plataforma Integrada MEC is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with Plataforma Integrada MEC.  If not, see <http://www.gnu.org/licenses/>.*/

// const = React.lazy(() => import());

import React, { useContext, useEffect, useState } from "react";
import { Store } from './Store'
import LoadingSpinner from './Components/LoadingSpinner';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import createBrowserHistory from 'history'
import './App.css';
import ContrastBar from './Components/Accessibility/ContrastBar';
import CookiesConsent from "./Components/CookiesConsent";
import Cookies from 'js-cookie';

import Gamefication from "./Admin/Pages/Pages/SubPages/Gamefication"
import GameficationCard from "./Admin/Components/Components/DataCards/GameficationCard"
import CreateItem from "./Admin/Components/Components/Inputs/CreateItem/CreateItem"
import EditItem from "./Admin/Components/Components/Inputs/EditItem/EditItem"
import GameficationAchieves from './Admin/Pages/Pages/SubPages/GameficationAchieves'
import AchievementCard from './Admin/Components/Components/DataCards/AchievementsCard'
import EditAchievement from "./Admin/Components/Components/Inputs/EditAchievement"
import CreateAchievements from './Admin/Components/Components/Inputs/CreateAchievements'
import GameficationRequires from './Admin/Pages/Pages/SubPages/GameficationRequires'
import EditRequirement from "./Admin/Components/Components/Inputs/EditRequirements"
import CreateRequirement from './Admin/Components/Components/Inputs/CreateRequirement'
import RequirementCard from './Admin/Components/Components/DataCards/RequirementCard'
import GameficationActions from './Admin/Pages/Pages/SubPages/GameficationActions'
import ActionCard from './Admin/Components/Components/DataCards/ActionCard'
import EditAction from "./Admin/Components/Components/Inputs/EditAction"
import CreateAction from "./Admin/Components/Components/Inputs/CreateAction"

const AdminTemplate = React.lazy(() => import('./Admin/Components/Components/AdminTemplate'));
const Header = React.lazy(() => import("./Components/Header"));
const Home = React.lazy(() => import('./Pages/HomeFunction'));
const Search = React.lazy(() => import("./Pages/Search"));
const EcFooter = React.lazy(() => import("./Components/EcFooter"));
const GNUAGPLfooter = React.lazy(() => import("./Components/AGPLFooter"));
const UserPage = React.lazy(() => import("./Pages/UserPage"));
const UserTerms = React.lazy(() => import("./Pages/UserTerms"));
const SummarizedUserTerms = React.lazy(() => import("./Pages/SummarizedUserTerms"));
const Contact = React.lazy(() => import("./Pages/Contact"));
const AboutPage = React.lazy(() => import("./Pages/AboutPage"));
const HelpCenter = React.lazy(() => import("./Pages/HelpCenter"));
const TabResoursePub = React.lazy(() => import("./Pages/TabsHelp/TabResoursePub"));
const TabResourseFind = React.lazy(() => import("./Pages/TabsHelp/TabResourseFind"));
const TabNetPart = React.lazy(() => import("./Pages/TabsHelp/TabNetPart"));
const TabManageAc = React.lazy(() => import("./Pages/TabsHelp/TabManageAc"));
const PasswordRecoveryPage = React.lazy(() => import("./Pages/PasswordRecoveryPage.js"));
const ChangePasswordPage = React.lazy(() => import("./Pages/ChangePasswordPage.js"));
const PageProfessor = React.lazy(() => import("./Pages/PageProfessor.js"));
const ResourcePage = React.lazy(() => import("./Pages/ResourcePage"));
const TermsPage = React.lazy(() => import("./Pages/TermsPage.js"));
const PublicationPermissionsPage = React.lazy(() => import("./Pages/PublicationPermissionsPage.js"));
const TabPlataformaMEC = React.lazy(() => import("./Pages/TabsHelp/TabPlataformaMEC"));
const ItemStore = React.lazy(() => import("./Pages/ItemStore.js"));
const EditProfilePage = React.lazy(() => import("./Pages/EditProfilePage.js"));
const PublicUserPage = React.lazy(() => import("./Pages/PublicUserPage.js"));
const UploadPage = React.lazy(() => import("./Pages/UploadPage.js"));
const EditLearningObjectPage = React.lazy(() => import("./Pages/EditLearningObjectPage.js"));
const SiteMap = React.lazy(() => import("./Pages/SiteMap"));
const Accessibility = React.lazy(() => import("./Pages/Accessibility"));
const CollectionPage = React.lazy(() => import("./Pages/CollectionPage.js"));
const FormationMaterialPage = React.lazy(() => import("./Pages/FormationMaterialPage.js"));
const FormationMaterialIframe = React.lazy(() => import("./Pages/FormationMaterialIframe.js"));
const MaterialPage = React.lazy(() => import("./Pages/MaterialPage"));
const PageNotFound = React.lazy(() => import("./Pages/PageNotFound.js"));
const EmailConfirmation = React.lazy(() => import("./Pages/EmailConfirmation.js"));

const NoteVariables = React.lazy(() => import("./Admin/Pages/Pages/SubPages/NoteVariables"));
const Institution = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Institutions"));
const SendEmail = React.lazy(() => import("./Admin/Pages/Pages/SubPages/SendEmail"));
const Inframe = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Inframe"));
const Languages = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Languages"));
const Activity = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Activity"));
const InstitutionCard = React.lazy(() => import("./Admin/Components/Components/DataCards/InstitutionsCard"));
const InstitutionsInput = React.lazy(() => import("./Admin/Components/Components/Inputs/IntitutionsInputs"));
const CreateInstitution = React.lazy(() => import("./Admin/Components/Components/Inputs/CreateInstitution"));
const NoteVarCard = React.lazy(() => import("./Admin/Components/Components/DataCards/NoteVarCard"));
const NoteVarInputs = React.lazy(() => import("./Admin/Components/Components/Inputs/NoteVarInputs"));
const EditLanguage = React.lazy(() => import("./Admin/Components/Components/Inputs/EditLanguage"));
const CreateLanguage = React.lazy(() => import("./Admin/Components/Components/Inputs/CreateLanguage"));
const ActivityCard = React.lazy(() => import("./Admin/Components/Components/DataCards/ActivityCard"));
const CommunityQuestions = React.lazy(() => import("./Admin/Pages/Pages/SubPages/CommunityQuestions"));
const CommunityCard = React.lazy(() => import("./Admin/Components/Components/DataCards/CommunityQuestionCard"));
const Collections = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Collections"));
const CollectionCard = React.lazy(() => import("./Admin/Components/Components/DataCards/CollectionCard"));
const EditCollection = React.lazy(() => import("./Admin/Components/Components/Inputs/EditCollection"));
const Ratings = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Rating"));
const RatingCard = React.lazy(() => import("./Admin/Components/Components/DataCards/RatingCard"));
const EditRating = React.lazy(() => import("./Admin/Components/Components/Inputs/EditRating"));
const CreateRating = React.lazy(() => import("./Admin/Components/Components/Inputs/CreateRating"));
const Questions = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Questions"));
const CreateQuestions = React.lazy(() => import("./Admin/Components/Components/Inputs/CreateQuestion"));
const EducationalObject = React.lazy(() => import("./Admin/Pages/Pages/SubPages/EducationalObjects"));
const EducationalObjectCard = React.lazy(() => import("./Admin/Components/Components/DataCards/EducationalObjectsCard"));
const EducationalObjectEdit = React.lazy(() => import("./Admin/Components/Components/Inputs/EditEducationalObect"));
const Complaints = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Complaints"));
const ComplaintCard = React.lazy(() => import("./Admin/Components/Components/DataCards/ComplaintsCard"));
const AproveTeacher = React.lazy(() => import("./Admin/Pages/Pages/SubPages/AproveTeacher"));
const UserCard = React.lazy(() => import("./Admin/Components/Components/DataCards/UserCard"));
const EditUser = React.lazy(() => import("./Admin/Components/Components/Inputs/EditUser"));
const UserList = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Users"));
const UserPermissions = React.lazy(() => import("./Admin/Pages/Pages/SubPages/Permissions"));
const EditRole = React.lazy(() => import("./Admin/Components/Components/Inputs/EditRoles"));
const CreateRole = React.lazy(() => import("./Admin/Components/Components/Inputs/CreateRole"));
const BlockedUser = React.lazy(() => import("./Admin/Pages/Pages/SubPages/BlockedUsers"));

async function supportsWebp() {
    if (!createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

const LoadingScreen = () => (
    <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }}>
        <LoadingSpinner />
    </div>
);

export default function App() {
    // eslint-disable-next-line
    const { state, dispatch } = useContext(Store);
    const [hideHeader, setHideHeader] = useState(false);
    const [hideFooter, setHideFooter] = useState(false);
    const [showBar,setShowBar] = useState(false);
    const [updateShowBar,setUpdateShowBar] = useState(true);
    const [awaitTest, setAwait] = useState(true);
    const PiwikReactRouter = require('piwik-react-router');
    const piwik = PiwikReactRouter({
        url: '//piwik.c3sl.ufpr.br/',
        siteId: 5
    });
    // const customHistory = createBrowserHistory()

    /* document.addEventListener('visibilitychange',(ev) => {
        if (document.visibilityState !== 'visible') {
            Cookies.set("showBar",true,{expires :1},{sameSite:'strict'})
        }
    }) */

    async function testWebpSupport() {
        if (await supportsWebp()) {
            localStorage.setItem('webpSupport', 'True');
        } else {
            localStorage.setItem('webpSupport', 'False');
        }
        setAwait(false);
    }

    useEffect(() => {
        if(updateShowBar){
            let aux = Cookies.get("showBar");
            console.log(aux)
            if (aux === undefined)
                aux=true;
            else
                aux=(aux==="true"); //Convert string to boolean
            setShowBar(aux);
            setUpdateShowBar(false);
        }
    },[updateShowBar])

    useEffect(() => {
        setHideHeader(String(window.location.href).includes("confirmacao-de-email"));
        setHideFooter(String(window.location.href).includes("iframe-colecao") || String(window.location.href).includes("confirmacao-de-email"));
    }, [window.location.href]);

    useEffect(() => {
        dispatch({
            type: "WINDOW_SIZE",
            innerWindow: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
        });
        testWebpSupport();
    }, []);

    useEffect(() => {
        state.contrast === "" ? document.body.style.backgroundColor = "white" : document.body.style.backgroundColor = "black"
    }, [state.contrast]);

    useEffect(() => {
        const setWindowSize = () => {
            dispatch({
                type: "WINDOW_SIZE",
                innerWindow: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
            });
        };

        window.addEventListener("resize", setWindowSize);

        return () => window.removeEventListener("resize", setWindowSize);
    }, [window.innerWidth, window.innerHeight]);

    return (
        // add piwik later
        // history={piwik.connectToHistory(customHistory)}
        <>
            {!awaitTest &&
                <React.Suspense fallback={<LoadingScreen />}>
                    {/*<BrowserRouter history={piwik.connectToHistory(customHistory)}>*/}
                    <BrowserRouter >
                        { !hideHeader && (
                            <div>
                                <ContrastBar />
                                <Header />
                            </div>
                        )}
                        <div
                            style={{
                                backgroundImage:
                                    "linear-gradient(to right,#ff7f00,#e81f4f,#673ab7,#00bcd4)",
                                height: "5px",
                            }}
                        ></div>
                        <link
                            href="https://fonts.googleapis.com/css?family=Kalam|Pompiere|Roboto&display=swap"
                            rel="stylesheet"
                        />
                        <div id="Conteudo_scroll"></div>
                        <Routes style={state.contrast === "" ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                            <Route path="/home" element={<Navigate to="/"/>} />
                            <Route path="/" element={<Home />} />
                            <Route path="/busca" element={<Search />} />
                            <Route path="/perfil" element={<UserPage />} />
                            <Route path="/editarperfil" element={<EditProfilePage />} />
                            <Route path="/recurso/:recursoId" element={<ResourcePage />} />
                            <Route path="/termos-publicar-recurso" element={<TermsPage />} />
                            <Route path="/permission" element={<PublicationPermissionsPage />} />
                            {/*<Route path="termos-de-uso#publicacoes-de-usuario" element={<}*/}
                            <Route path="/ajuda" element={<HelpCenter />} />
                            <Route path="/contato/:tipo" element={<Contact />} />
                            <Route path="/termos-resumido" element={<SummarizedUserTerms />} />
                            <Route path="/termos" element={<UserTerms />} />
                            <Route path="/sobre" element={<AboutPage />} />
                            <Route path="/mapa-site" element={<SiteMap />} />
                            <Route path="/acessibilidade" element={<Accessibility />} />
                            <Route path="/publicando-recurso" element={<TabResoursePub />} />
                            <Route path="/encontrando-recurso" element={<TabResourseFind />} />
                            <Route path="/participando-da-rede" element={<TabNetPart />} />
                            <Route path="/gerenciando-conta" element={<TabManageAc />} />
                            <Route path="/plataforma-mec" element={<TabPlataformaMEC />} />
                            <Route path="/recuperar-senha/alterar-senha" element={<ChangePasswordPage />} />
                            <Route path="/recuperar-senha" element={<PasswordRecoveryPage />} />
                            <Route path="/usuario-publico/:userId" element={<PublicUserPage />} />
                            <Route
                                path="/editar-recurso/:recursoId"
                                element={<EditLearningObjectPage />}
                            />
                            <Route path="/professor" element={<PageProfessor />} />
                            <Route path="/upload" element={<UploadPage />} />
                            <Route path="/loja" element={<ItemStore />} />
                            <Route path="/colecao-do-usuario/:id" element={<CollectionPage />} />
                            <Route path="/colecao" element={<FormationMaterialPage />} />
                            <Route path="/topico" element={<FormationMaterialPage />} />
                            <Route path="/iframe-colecao" element={<FormationMaterialIframe />} />
                            <Route path="/material-formacao" element={<MaterialPage />} />
                            <Route path="/confirmacao-de-email" element={<EmailConfirmation />} />

                            <Route
                                path="/admin/home"
                                element={<AdminTemplate inner={<Inframe />} />}/>
                            <Route
                                path="/admin/institutions"
                                element={<AdminTemplate inner={<Institution />} />}/>
                            <Route
                                path="/admin/institution"
                                element={<AdminTemplate inner={<InstitutionCard />} />}/>
                            <Route
                                path="/admin/institutionEdit"
                                element={<AdminTemplate inner={<InstitutionsInput />} />}/>
                            <Route
                                path="/admin/InstitutionCreate"
                                element={<AdminTemplate inner={<CreateInstitution />} />}/>
                            <Route
                                path="/admin/noteVars"
                                element={<AdminTemplate inner={<NoteVariables />} />}/>
                            <Route
                                path="/admin/noteVar"
                                element={<AdminTemplate inner={<NoteVarCard />} />}/>
                            <Route
                                path="/admin/noteVarEdit"
                                element={<AdminTemplate inner={<NoteVarInputs />} />}/>
                            <Route
                                path="/admin/languages"
                                element={<AdminTemplate inner={<Languages />} />}/>
                            <Route
                                path="/admin/languageEdit"
                                element={<AdminTemplate inner={<EditLanguage />} />}/>
                            <Route
                                path="/admin/languageCreate"
                                element={<AdminTemplate inner={<CreateLanguage />} />}/>
                            <Route
                                path="/admin/CommunityQuestions"
                                element={<AdminTemplate inner={<CommunityQuestions />} />}/>
                            <Route
                                path="/admin/CommunityQuestion"
                                element={<AdminTemplate inner={<CommunityCard />} />}/>
                            <Route
                                path="/admin/Collections"
                                element={<AdminTemplate inner={<Collections />} />}/>
                            <Route
                                path="/admin/Collection"
                                element={<AdminTemplate inner={<CollectionCard />} />}/>
                            <Route
                                path="/admin/EditCollection"
                                element={<AdminTemplate inner={<EditCollection />} />}/>
                            <Route
                                path="/admin/Ratings"
                                element={<AdminTemplate inner={<Ratings />} />}/>
                            <Route
                                path="/admin/Rating"
                                element={<AdminTemplate inner={<RatingCard />} />}/>
                            <Route
                                path="/admin/EditRating"
                                element={<AdminTemplate inner={<EditRating />} />}/>
                            <Route
                                path="/admin/CreateRating"
                                element={<AdminTemplate inner={<CreateRating />} />}/>
                            <Route
                                path="/admin/Questions"
                                element={<AdminTemplate inner={<Questions />} />}/>
                            <Route
                                path="/admin/CreateQuestion"
                                element={<AdminTemplate inner={<CreateQuestions />} />}/>
                            <Route
                                path="/admin/activities"
                                element={<AdminTemplate inner={<Activity />} />}/>
                            <Route
                                path="/admin/activity"
                                element={<AdminTemplate inner={<ActivityCard />} />}/>
                            <Route
                                path="/admin/learningObjects"
                                element={<AdminTemplate inner={<EducationalObject />} />}/>
                            <Route
                                path="/admin/learningObject"
                                element={<AdminTemplate inner={<EducationalObjectCard />} />}/>
                            <Route
                                path="/admin/learningObjectEdit"
                                element={<AdminTemplate inner={<EducationalObjectEdit />} />}/>
                            <Route
                                path="/admin/complaints"
                                element={<AdminTemplate inner={<Complaints />} />}/>
                            <Route
                                path="/admin/complaint"
                                element={<AdminTemplate inner={<ComplaintCard />} />}/>
                            <Route
                                path="/admin/users/teacher_requests"
                                element={<AdminTemplate inner={<AproveTeacher />} />}/>
                            <Route
                                path="/admin/usersList"
                                element={<AdminTemplate inner={<UserList />} />}/>
                            <Route
                                path="/admin/user"
                                element={<AdminTemplate inner={<UserCard />} />}/>
                            <Route
                                path="/admin/EditUser"
                                element={<AdminTemplate inner={<EditUser />} />}/>
                            <Route
                                path="/admin/permissions"
                                element={<AdminTemplate inner={<UserPermissions />} />}/>
                            <Route
                                path="/admin/EditPermissions"
                                element={<AdminTemplate inner={<EditRole />} />}/>
                            <Route
                                path="/admin/CreateRole"
                                element={<AdminTemplate inner={<CreateRole />} />}/>
                            <Route
                                path="/admin/BlockedUsers"
                                element={<AdminTemplate inner={<BlockedUser />} />}/>
                            <Route
                                path="/admin/sendEmail"
                                element={<AdminTemplate inner={<SendEmail />} />}/>
                            {/* Rotas de Gamefication */}
                            <Route path="/admin/gamefication" element={<Gamefication />} />
                            <Route path="/admin/item/:id" element={<GameficationCard />} />
                            <Route path="/admin/createItem" element={<CreateItem />} />
                            <Route path="/admin/EditItem/:id" element={<EditItem />} />
                            <Route path="/admin/achievements" element={<GameficationAchieves />} />
                            <Route path="/admin/achievement/:id" element={<AchievementCard />} />
                            <Route path="/admin/EditAchievement/:id" element={<EditAchievement />} />
                            <Route path="/admin/createAchievements" element={<CreateAchievements />} />
                            <Route path="/admin/requirements" element={<GameficationRequires />} />
                            <Route path="/admin/EditRequirement/:id" element={<EditRequirement />} />
                            <Route path="/admin/CreateRequirement" element={<CreateRequirement />} />
                            <Route path="/admin/requirement/:id" element={<RequirementCard />} />
                            <Route path="/admin/actions" element={<GameficationActions />} />
                            <Route path="/admin/action/:id" element={<ActionCard />} />
                            <Route path="/admin/EditAction/:id" element={<EditAction />} />
                            <Route path="/admin/CreateAction" element={<CreateAction />} />
                            
                            <Route path='*' element={<PageNotFound />} />
                        </Routes>
                        {!hideFooter && (
                            <div>
                                {showBar &&
                                    CookiesConsent(setUpdateShowBar,state.contrast)
                                }
                            <div id="Rodape_scroll">
                                <EcFooter contrast={state.contrast} />
                                <GNUAGPLfooter contrast={state.contrast} />
                            </div>
                            </div>
                        )}
                    </BrowserRouter>
                </React.Suspense>
            }
        </>
    );
}